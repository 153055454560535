import React, { useEffect, useState } from "react";
import { Input } from "@fluentui/react-northstar";
import { MdError } from "react-icons/md";
import { checkIfInputIsValid } from "../../../utility/validator/controlvalidations";
import "./TextBox.css";

function TextBox(props) {
  const [currentValue, setCurrentValue] = useState(props?.value);
  const [hasAnyError, setError] = useState(props?.hasAnyError);
  const [errorMessage, setErrorMessage] = useState(props?.errMessage);
  const allValidations = props?.validations;
  const isFromPrompt = props.isFromPromptPlayground;

  useEffect(() => {
    setError(props.hasAnyError);
    setErrorMessage(props.errMessage);
  }, [props?.hasAnyError]);

  useEffect(() => {
    if (props.editMode === true) {
      setCurrentValue(props?.value);
      props.updateValidationState(
        props?.name,
        props?.value,
        props?.hasAnyError,
        props?.errMessage
      );
      props.setEditProperties({ [props?.name]: false });
    }
  }, [props?.value]);

  useEffect(() => {
    if (
      props.isPolls == true ||
      props.isCustomUserGroup == true ||
      props.isFromPromptPlayground === true ||
      props.isProfileSettings == true || props?.isLibrarySearch == true ||
      props.isChannelSettings == true
    ) {
      setCurrentValue(props?.value);
    }
  }, [props?.value]);

  const handleInputChange = (e) => {
    if (allValidations != undefined && allValidations.length > 0) {
      const { hasError, errMessage } = checkIfInputIsValid(
        allValidations,
        e.target.value
      );
      setError(hasError);
      setErrorMessage(errMessage);
      props?.onChange(
        e,
        props?.fieldsName,
        props?.name,
        props?.isCustomField,
        props?.isContentField,
        props?.parentName,
        hasError,
        errMessage
      );
    } else {
      props?.onChange(
        e,
        props?.fieldsName,
        props?.name,
        props?.isCustomField,
        props?.isContentField,
        props?.parentName,
        false,
        ""
      );
    }
    setCurrentValue(e.target.value);
  };

  return (
    <>
      <div className="formTextInputStyles" style={{ '--prompt-input-color': isFromPrompt ? 'black' : '' }}>
        <Input
          placeholder={props?.placeholder}
          data-testid={props?.name}
          inverted={props?.inverted}
          icon={props?.icon}
          iconPosition={props?.iconPosition}
          fluid={true}
          clearable={props?.clearable}
          inline={props?.inline}
          onKeyDown={(event) => {
            if (event?.key === 'Enter' && typeof props?.onKeyDown === 'function') {
              props?.onKeyDown()
            }
          }}
          disabled={props?.disabled}
          className={`textInputStyles ${props?.className} ${hasAnyError ? "textbox_error" : ""
            }`}
          errorIndicator={props?.errorIndicator}
          onChange={(e) => {
            handleInputChange(e);
          }}
          required={props?.required}
          type={props?.type}
          value={currentValue}
          title={props?.title}
          id={props?.id}
        />
      </div>
      <div>
        {props?.additionalMessage !== "" && (
          <span className="additionalMessage">{props?.additionalMessage}</span>
        )}
      </div>
      <div>
        {hasAnyError && (
          <>
            <MdError color="rgb(196, 49, 75)" />
            <span className="errMessage">{errorMessage}</span>
          </>
        )}
      </div>
    </>
  );
}

export default TextBox;
