// External libraries
import React, { Suspense, useEffect, useState, lazy } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { app } from "@microsoft/teams-js";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
// Services
import Connector from "./services/SignalR/SignalRService";
import { deleteData } from "./services/customApis";
// Components
import Sidebar from "./components/sidebar/sideBar";
import TopBar from "./components/topbar/topBar";
import CommonLayout from "./commonui/commonlayout/commonLayout";
import ConfirmationPage from "./commonui/confirmationpage/confirmationpage";
import ReusableLoader from "./commonui/reusableloader/reusableLoader";
import WelcomePage from "./components/welcomepage/welcomepage";
import UnauthorizedPage from "./commonui/unauthorizedpage/unauthorized";
import Events from "./components/events/events";
import Notification from "./components/notification/notification";
import NotificationPanel from "./components/notification/notificationPanel";
import ToasterNotifications from "./commonui/toasternotifications/toasterNotifications";
import Subscription from "./components/subscription/subscription";
import BillingDetails from "./components/billingdetails/billingDetails";
import CustomUserGroups from "./components/customusergroups/customUserGroups";
import UserGroupCreationForm from "./components/usergroupcreationform/userGroupCreationForm";
import ContactUs from "./components/contactus/contactUs";
import Apps from "./components/apps/apps";
import AppsDetails from "./components/apps/appsdetailpage/appsDetails";
import BulletinBoard from "./components/bulletinboard/bulletinBoard";
import UnauthorizedWithTopBar from "./commonui/unauthorizedpage/unauthorizedWithTopBar";
import InternalAppTabDetails from "./components/InternalApps/InternalAppTabDetails";
import DatabaseGrid from "./components/InternalApps/databaseGrid/databaseGrid";
import LibraryGrid from "./components/InternalApps/libraryGrid/libraryGrid";
import NavBar from "./components/navBar/navBar";
import LogoutPage from "./components/logoutpage/logoutPage";
import RegisterPage from "./components/registerpage/registerPage";
// Contexts
import {
  useHamburgerMenuContext,
  useMenuContext,
  useSideNavbarDataContext,
} from "./context/menucontext/menuContext";
import SearchContext from "./context/searchcontext/searchContext";
import { UseNotificationContext } from "./context/notificationcontext/notificationContext";
import { UseToasterNotificationsContext } from "./context/toasternotificationscontext/toasterNotificationsContext";
import { UseUserInfoContext } from "./context/usercontext/userContext";
// Utility
import Constant from "./utility/constants";
import APIEndPoints from "./utility/apiendpoints";
import {
  findMenuItem,
  getUrlParams,
  getContentTypePathsById,
  getContentTypeNamesById2,
} from "./utility/utility";
// Data
import sideNavMainMenuData from "./data/menu/sideNavMainMenuData";
import sideNavSettingsData from "./data/menu/sideNavSettingsData";
// Styles
import "./App.css";

const PdfContainer = lazy(() =>
  import("./components/pdfcontainer/pdfContainer")
);

const PollsCurationForm = lazy(() =>
  import("./components/polls/pollsCurationForm/pollsCurationForm")
);

const StaticPageContainer = lazy(() =>
  import("./components/staticpagesforcatalystapp/staticPageContainer")
);

const ContentCurationForm = lazy(() =>
  import("./components/contentcurationform/contentCurationForm")
);

const PrivacyNoticePopup = lazy(() =>
  import("./components/privacynotice/privacyNoticePopup")
);

const WhatsCatalyst = lazy(() =>
  import("./components/whatscatalyst/whatsCatalyst")
);
const Profile = lazy(() => import("./components/profile/profile"));
const MyExperience = lazy(() =>
  import("./components/myexperience/myExperience")
);
const Search = lazy(() => import("./components/search/search"));
//const Events = lazy(() => import("./components/events/events"));
// const TheCollective = lazy(() =>
//   import("./components/thecollective/theCollective")
// );
const Resources = lazy(() => import("./components/resources/resources"));
// const MarketPlace = lazy(() => import("./components/marketplace/marketPlace"));
const AppCatalog = lazy(() => import("./components/appcatalog/appCatalog"));
const DataTransform = lazy(() =>
  import("./components/datatransform/dataTransform")
);
const Reports = lazy(() => import("./components/reports/reports"));
const Visualizations = lazy(() =>
  import("./components/visualizations/visualizations")
);
const OpportunityAssist = lazy(() =>
  import("./components/opportunityassist/opportunityAssist")
);
const DetailsViewPage = lazy(() =>
  import("./components/detailsviewpage/detailsViewPage")
);
const PinLibraryDetails = lazy(() =>
  import(
    "./components/visualizations/pinlibrary/pinlibrarydetails/pinLibraryDetails"
  )
);
const UserManagement = lazy(() =>
  import("./components/usermanagement/UserManagement")
);

const PdfPlayground = lazy(() =>
  import("./components/InternalApps/PdfPlayground/pdfPlayground")
);

const ChannelCreation = lazy(() =>
  import("./components/channelCreation/channelCreation")
);

const ChannelApproveDenyComponent = lazy(() =>
  import(
    "./components/channelCreation/channelapprovedenypage/channelApproveDeny"
  )
);
// const RequestChannel = lazy(() =>
//   import("./components/channelCreation/requestchannel/RequestChannelPage")
// );
const PendingApproval = lazy(() =>
  import("./components/channelCreation/pendingapprovalspage/pendingApproval")
);

const RequestChannelComponent = lazy(() =>
  import("./components/channelCreation/requestchannel/RequestChannelPage")
);

const routesObject = [
  {
    route: "/",
    component: <BulletinBoard />,
  },
  {
    route: "/itemDetails/:metaDataId/:contentTypeId/:isGlobal",
    component: <DetailsViewPage />,
    options: {
      disableMenu: true,
      disableSubMenu: true,
    },
  },
  {
    route: "/appsDetails/:metaDataId/:contentTypeId/:groupMetadataId/:title",
    component: <AppsDetails />,
  },
  // {
  //   route: "/whatscatalyst/",
  //   component: <WhatsCatalyst />,
  // },
  {
    route: "/settings",
    component: <Profile />,
  },
  {
    route: "/settings/profile",
    component: <Profile />,
  },
  {
    route: "/settings/myexperience",
    component: <MyExperience />,
  },
  {
    route: "/settings/subscription",
    component: <Subscription />,
  },
  {
    route: "/settings/billingdetails",
    component: <BillingDetails />,
  },
  {
    route: "/settings/customusergroups",
    component: <CustomUserGroups />,
  },
  {
    route: "/search",
    component: (
      <SearchContext>
        <Search />
      </SearchContext>
    ),
  },
  {
    route: "/yourchannel",
    component: <UserManagement />,
  },
  {
    route: "/channelManagement",
    component: <ChannelCreation />,
  },
  {
    route: "/requestChannel",
    //component: <RequestChannel />,
    component: <RequestChannelComponent />,
  },
  {
    route: "/channelSettings",
    component: <ChannelApproveDenyComponent />,
  },
  {
    route: "/pendingApprovals",
    component: <PendingApproval />,
  },
  {
    route: "/channelSettings/:ID",
    component: <ChannelApproveDenyComponent />,
  },
];

const detailsRoutes = [
  {
    route: "/visualizationlibrary/details/:metaDataId/:isGlobal",
    component: <PinLibraryDetails />,
    options: {
      disableMenu: true,
      disableSubMenu: true,
    },
  },
  {
    route: "/pdfDetails/:metaDataId/:contentTypeId/:isGlobal",
    component: <PdfContainer />,
    options: {
      disableMenu: true,
      disableSubMenu: true,
    },
  },
];

const staticPageRoutes = [
  {
    route: "/termsofuse",
    component: (
      <StaticPageContainer
        apiName={"/PrivacyNotice/getPrivacyNotice"}
        tabName={"Terms of use"}
      />
    ),
  },
  {
    route: "/privacypolicy",
    component: (
      <StaticPageContainer
        apiName={"/PrivacyNotice/getPrivacyNotice"}
        tabName={"Privacy policy"}
      />
    ),
  },
];

const scrollRoutes = [
  {
    route: "/events",
    component: <Events />,
  },
  {
    route: "/conferences",
    component: <Events />,
  },
  {
    route: "/webcasts",
    component: <Events />,
  },
  {
    route: "/podcasts",
    component: <Events />,
  },
  // {
  //   route: "/thecollective",
  //   component: <TheCollective />,
  // },
  // {
  //   route: "/perspectives",
  //   component: <TheCollective />,
  // },
  // {
  //   route: "/surveys",
  //   component: <TheCollective />,
  // },
  // {
  //   route: "/polls",
  //   component: <TheCollective />,
  // },
  {
    route: "/resources",
    component: <Resources />,
  },
  {
    route: "/taxalerts",
    component: <Resources />,
  },
  {
    route: "/taxagenda",
    component: <Resources />,
  },
  {
    route: "/polls",
    component: <Resources />,
  },
  {
    route: "/guides",
    component: <Resources />,
  },
  {
    route: "/executives",
    component: <Resources />,
  },
  {
    route: "/perspectives",
    component: <Resources />,
  },
  {
    route: "/accountinglink",
    component: <Resources />,
  },
  // {
  //   route: "/marketplace",
  //   component: <MarketPlace />,
  // },
  // {
  //   route: "/appcatalog",
  //   component: <MarketPlace />,
  // },
  // {
  //   route: "/taxOnDemand",
  //   component: <MarketPlace />,
  // },
  {
    route: "/eyappcatalog",
    component: <AppCatalog />,
  },
  {
    route: "/datatransform",
    component: <DataTransform />,
  },
  {
    route: "/dataDrop",
    component: <DataTransform />,
  },
  {
    route: "/dataLibrary",
    component: <DataTransform />,
  },
  {
    route: "/dataRetrieve",
    component: <DataTransform />,
  },
  {
    route: "/eySubscription",
    component: <DataTransform />,
  },
  {
    route: "/reports",
    component: <Reports />,
  },
  {
    route: "/createReport",
    component: <Reports />,
  },
  {
    route: "/reportLibrary",
    component: <Reports />,
  },
  {
    route: "/visualizations",
    component: <Visualizations />,
  },
  {
    route: "/createPin",
    component: <Visualizations />,
  },
  {
    route: "/pinBoard",
    component: <Visualizations />,
  },
  {
    route: "/visualizationlibrary",
    component: <Visualizations />,
  },
  {
    route: "/apps",
    component: <Apps />,
  },
  {
    route: "/apps/LibraryGrid",
    component: <LibraryGrid />,
  },
  {
    route: "/apps/LibrarySearchPage",
    component: <InternalAppTabDetails />,
  },
  {
    route: "/apps/DatabaseGrid",
    component: <DatabaseGrid />,
  },
  {
    route:
      "/apps/tpAlertsLibraryGrid/pdfPlayground/:metaDataId/:contentTypeId/:isGlobal",
    component: <PdfPlayground />,
  },
  {
    route: "/apps/LibraryGrid/pdfPlayground",
    component: <PdfPlayground />,
  },
  {
    route: "/opportunityassist",
    component: <OpportunityAssist />,
  },
  {
    route: "/robobudget",
    component: <OpportunityAssist />,
  },
  {
    route: "/roboscope",
    component: <OpportunityAssist />,
  },
  {
    route: "/eycampaigns",
    component: <OpportunityAssist />,
  },
  {
    route: "/engagementMetrics",
    component: <OpportunityAssist />,
  },
  {
    route: "/notifications",
    component: <Notification />,
  },
  {
    route: "/contentCuration",
    component: <ContentCurationForm />,
  },
  {
    route: "/contactUs",
    component: <ContactUs />,
  },
  {
    route: "/newPollCuration",
    component: <PollsCurationForm />,
  },
  {
    route: "/eycatalyst",
    component: <WhatsCatalyst />,
  },
  {
    route: "/customUserGroupForm",
    component: <UserGroupCreationForm />,
  },
];
var deepNavExecuted = false;
var navExecuted = false;

function App(props) {
  useSessionTimeout();
  const nav = useNavigate();
  // const [isUserAllowed, setUserValidation] = useState(false);
  // const [authCheckDone, SetAuthCheck] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [globalMessage, setGlobalMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [splitPage, setSplitPage] = useState([]);
  //const [privacyAgreementDeclined, setPrivacyAgreementDeclined] = useState(false);

  //init userInfo
  let { userInfo } = UseUserInfoContext();
  const { notificationMsgsData, handleNotificationMsgsData } =
    UseToasterNotificationsContext();

  const isAuthenticated = useIsAuthenticated();

  const { handleChangeNav } = useMenuContext();
  //signal R initiation -  In app Notification
  //let initTheConnection = Connector();
  // const eventFunc = useCallback(() => {
  //   Connector().connect(undefined, (handler) => {
  //     handler.onNotificationSent((messageType, header) => {
  //       console.log(header);
  //       setGlobalMessage(header);
  //       setMessageType(messageType);
  //     });
  //   });
  // }, []);

  const eventNotify = () => {
    handleNotifications({
      reloadTheNotification: true,
    });
  };

  useEffect(() => {
    if (globalMessage !== "") {
      setTimeout(() => {
        handleNotificationMsgsData({
          showMsgBar: true,
          started: false,
          completed: true,
          msg: globalMessage,
          type: messageType,
          isFailed: false,
        });
        setGlobalMessage("");
        setMessageType("");
        eventNotify();
      }, 5000);
    }
  }, [globalMessage]);

  // Redirection from deep link
  useEffect(() => {
    const checkUserIsValid = async () => {
      app
        .initialize()
        .then(() => {
          app.getContext().then((item) => {
            //console.log(item);
            if (item.page.id === Constant.HomeTab) {
              if (item.page?.subPageId?.from === "SearchTabSidebar") {
                nav(item.page?.subPageId?.to);
              } else if (item.page.subPageId) {
                let subPageId = item.page.subPageId;
                let splitPage = subPageId.split(";");
                setSplitPage(splitPage);
              }
            }
          });
        })
        .catch((error) => {
          const url = new URL(window.location.href);
          // Get the pathname and split it into parts
          const parts = url.pathname.split("/");

          // Remove the first part if it's an empty string (because the pathname starts with a slash)
          if (parts[0] === "") {
            parts?.shift();
          }
          let splitPage = parts[parts.length - 1]?.split(";");
          if (splitPage.length > 1 && splitPage[0] !== "") {
            setSplitPage(splitPage);
          }
        });
      app.notifySuccess();
    };
    checkUserIsValid();
  }, []);

  const getCompanyIdIfPresent = (curUserId) => {
    let val = window.localStorage.getItem("curUserSelectedCompany");
    let companyId = userInfo?.allCompanies?.filter(
      (company) => company.companyId == parseInt(curUserId)
    );
    if (companyId.length > 0) {
      return companyId[0].key;
    } else {
      if (val == "undefined" || val == null || val == undefined || val == "") {
        return userInfo.allCompanies[0].key;
      } else {
        let parsedValue = JSON.parse(val);
        let checkIfExists = userInfo.allCompanies.filter(
          (item) => item.key == parsedValue.key
        );
        if (checkIfExists.length > 0) {
          return checkIfExists[0].key;
        } else {
          return userInfo.allCompanies[0].key;
        }
      }
    }
  };

  const getNavLink = (curUserId) => {
    let contentTypePath = getContentTypePathsById(parseInt(splitPage[2]));
    if (splitPage?.[4]?.toLowerCase() == "true") {
      let companyId = userInfo?.allCompanies?.filter(
        (company) => company.companyId == parseInt(curUserId)
      );
      if (companyId.length > 0) {
        return "/";
      } else {
        return contentTypePath;
      }
    } else {
      return contentTypePath;
    }
  };

  useEffect(() => {
    if (splitPage?.length > 0 && userInfo.allCompanies?.length > 0) {
      if (
        userInfo.allCompanies?.filter(
          (company) => company.companyId == parseInt(splitPage[1])
        )?.length > 0 ||
        splitPage[0]?.toLowerCase() == "true"
      ) {
        if (splitPage?.[2]) {
          if (splitPage[2]?.toLowerCase() == "notifications") {
            nav(`/${splitPage[2]}`, {
              state: {
                companyId: splitPage[1],
              },
            });
          } else if (splitPage[8]?.toLowerCase() == "notifications") {
            nav(`/notifications`, {
              state: {
                companyId: splitPage[1],
              },
            });
          } else if (splitPage?.[8]?.toLowerCase() == "reportlibrary") {
            nav(`/reportLibrary`, {
              state: {
                companyId: splitPage[1],
              },
            });
          } else if (splitPage?.[8]?.toLowerCase() == "datalibrary") {
            nav(`/dataLibrary`, {
              state: {
                companyId: splitPage[1],
              },
            });
          } else if (splitPage?.[8]?.toLowerCase() == "datadrop") {
            nav(`/notifications`, {
              state: {
                companyId: splitPage[1],
              },
            });
          } else {
            if (parseInt(splitPage[2]) > 0) {
              let contentTypeName = getContentTypeNamesById2(
                parseInt(splitPage[2])
              );
              let navLink = getNavLink(splitPage[1]);
              nav(`${navLink}`, {
                state: {
                  isGlobal:
                    splitPage[0]?.toLowerCase() == "true" ? true : false,
                  companyId:
                    splitPage[0]?.toLowerCase() == "true"
                      ? parseInt(getCompanyIdIfPresent(splitPage[1]))
                      : splitPage[1] ?? parseInt(splitPage[1]),
                  contentType: splitPage[2] ?? parseInt(splitPage[2]),
                  metaDataId: splitPage[3] ?? parseInt(splitPage[3]),
                  contentTypeName: contentTypeName,
                  pinMetaDataId: splitPage[5] ?? parseInt(splitPage[5]),
                  uniqueId: splitPage[6] ?? parseInt(splitPage[6]),
                  templateId: splitPage[7] ?? parseInt(splitPage[7]),
                  isFromBB: navLink == "/" ? true : false,
                  isFromAF: splitPage?.[8] ? true : false,
                },
              });
            }
          }
        }
      } else if (splitPage?.[2]?.toLowerCase() === "channelsettings") {
        //my content here
        console.log(splitPage?.[2].toLowerCase());
        nav(`/channelSettings`, {
          state: {
            companyId: splitPage[0],
            clientId: splitPage[1],
          },
        });
      } else {
        if (splitPage?.length > 0) {
          if (splitPage[0]?.toLowerCase() == "isinviteduser") {
            nav("/");
          } else nav("/unauthorized");
        }
      }
      setTimeout(() => {
        deepNavExecuted = true;
      }, 5000);
    } else if (splitPage?.length > 0) {
      if (splitPage[0]?.toLowerCase() == "isinviteduser") {
        nav("/");
      }
    }
  }, [userInfo.allCompanies, splitPage]);

  useEffect(() => {
    if (userInfo.isAuthorized == false) {
      // setUserValidation(false);
      // SetAuthCheck(true);
      setisLoading(false);
    } else {
      if (userInfo.allCompanies.length != 0) {
        // setUserValidation(true);
        // SetAuthCheck(true);
        //console.log(" userInfo before setting isLoading to FALSE=> ", userInfo);
        setisLoading(false);
        //signal R initiation -  In app Notification
        let initTheConnection = Connector();
        Connector().connect(undefined, (handler) => {
          handler.onNotificationSent((messageType, header) => {
            console.log(header);
            setGlobalMessage(header);
            setMessageType(messageType);
          });
        });
      }
    }
  }, [userInfo.isAuthorized, userInfo.allCompanies.length]);

  useEffect(() => {
    if (userInfo.newUser || userInfo.apiFailed) {
      setisLoading(false);
    }
  }, [userInfo.newUser, userInfo.apiFailed]);

  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
  const { sideNavbarData } = useSideNavbarDataContext();
  const { notifications, handleNotifications } = UseNotificationContext();

  let path = window.location?.pathname.split("/")[1].toLowerCase();
  // let showSideNavbar = path !== "search";
  let showSideNavbar = true;
  path === "whatscatalyst" &&
    sideNavbarData?.map((item) => {
      return item?.menu?.map((menu) => {
        return routesObject.push({
          route: menu?.to,
          component: <WhatsCatalyst />,
        });
      });
    });

  useEffect(() => {
    let fromDetailPage = getUrlParams("fromDetailsPage");
    if (userInfo.teams.companyId) {
      app.getContext().then((item) => {
        if (
          (item?.page?.subPageId === "" || deepNavExecuted) &&
          fromDetailPage === null
        ) {
          let path = window.location?.pathname.split("/")[1].toLowerCase();
          //console.log(path);
          navExecuted = false;

          if (path === "settings") {
            sideNavSettingsData[0].menu?.map((menu) => {
              if (
                menu?.to &&
                !navExecuted &&
                userInfo?.teams?.featureFlagsObj &&
                userInfo.teams.featureFlagsObj[menu?.title]?.isEnabled
              ) {
                !navExecuted && nav(menu?.to);
                navExecuted = true;
                return true;
              }
            });
          } else {
            sideNavbarData?.map((section) => {
              return section?.menu?.map((menu) => {
                menu?.submenu.map((submenu) => {
                  if (
                    userInfo?.teams?.featureFlagsObj &&
                    userInfo.teams.featureFlagsObj[submenu.title]?.isEnabled
                  ) {
                    if (!navExecuted) {
                      nav(submenu?.to);
                      handleChangeNav(
                        findMenuItem(sideNavMainMenuData, submenu?.to)
                      );
                      navExecuted = true;
                      return true;
                    }
                  }
                });
                if (
                  menu?.to &&
                  !navExecuted &&
                  userInfo?.teams?.featureFlagsObj &&
                  userInfo.teams.featureFlagsObj[menu?.title]?.isEnabled
                ) {
                  // if (menu.title !== "Bulletin Board") {
                  //nav(menu?.to);
                  //handleChangeNav(findMenuItem(sideNavMainMenuData, menu?.to));
                  // }
                  navExecuted = true;
                  return true;
                }
              });
            });
          }
        }
      });
      fromDetailPage &&
        window.history.replaceState(null, "", window.location.pathname);
    }
  }, [userInfo.teams.featureFlagsObj]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        !event.target.closest(".sidebar") &&
        !event.target.closest(".hamburgerMenu") &&
        toggleHamburger &&
        window.innerWidth < 901
      ) {
        handleHamburgerChange();
      }
    };

    const menuOverlayElements = document.querySelectorAll(".sidebar");
    if (menuOverlayElements.length > 0 && window.innerWidth < 901) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [userInfo.loading, userInfo.teams, toggleHamburger]);

  return (
    <>
      {userInfo?.loggedInWeb == null ? (
        <ReusableLoader isComponentBody={true} isOverlay={true} />
      ) : (
        <>
          {(isAuthenticated || userInfo?.loggedInWeb === false) &&
            (staticPageRoutes.filter(
              (item) =>
                item.route.includes(window.location.pathname) &&
                window.location.pathname != "/"
            ).length > 0 ? (
              //add the static route rendering
              <Routes>
                {staticPageRoutes.map((o, index) => {
                  return (
                    <Route
                      path={o.route}
                      element={
                        <>
                          <div
                            className={`sideBody fullBody ${
                              toggleHamburger ? "menuOverlay" : ""
                            }`}
                          >
                            <Suspense
                              fallback={
                                <ReusableLoader
                                  isComponentBody={true}
                                  isOverlay={true}
                                />
                              }
                            >
                              {o.component}
                            </Suspense>
                          </div>
                        </>
                      }
                      key={index}
                    />
                  );
                })}
              </Routes>
            ) : isLoading || userInfo.loading ? (
              <ReusableLoader isAppBody={true} isOverlay={true} />
            ) : (
              <>
                {" "}
                {
                  // isUserAllowed &&
                  //   authCheckDone &&
                  !userInfo.apiFailed &&
                    userInfo?.teams?.privacyAgreementAccepted &&
                    !userInfo?.teams?.privacyAgreementDeclined && (
                      <>
                        <Routes>
                          {scrollRoutes.map((o, index) => {
                            return (
                              <Route
                                path={o.route}
                                element={
                                  <>
                                    {userInfo.loggedInWeb == true && <NavBar />}
                                    <TopBar context={props.context} />
                                    {!userInfo.loading ? (
                                      <div
                                        className={`teamAppContainer ${
                                          toggleHamburger
                                            ? "teamAppContainer_lg"
                                            : "teamAppContainer_sm"
                                        }  ${
                                          showSideNavbar ? "" : " hideSidebar"
                                        }`}
                                        style={
                                          !toggleHamburger &&
                                          notifications.isFullPageNotification
                                            ? { gridTemplateColumns: "1px 1fr" }
                                            : {}
                                        }
                                      >
                                        <div
                                          id="adjustToaster"
                                          className={
                                            notificationMsgsData.showMsgBar ===
                                            true
                                              ? `common_container_header1 showToaster  ${
                                                  path === "yourchannel"
                                                    ? " userManagementDialogToaster"
                                                    : ""
                                                }`
                                              : `backgroundFix`
                                          }
                                        >
                                          <ToasterNotifications
                                            showClass="toasterNotificationWrapper1"
                                            data-testid="toaster-notification"
                                          />
                                        </div>
                                        <Sidebar />
                                        <div
                                          className={`sideBody ${
                                            toggleHamburger ? "menuOverlay" : ""
                                          }`}
                                        >
                                          {/* <div
                                      id="adjustToaster"
                                      className={
                                        notificationMsgsData.showMsgBar === true
                                          ? `common_container_header1 showToaster  ${
                                              path === "yourchannel"
                                                ? " userManagementDialogToaster"
                                                : ""
                                            }`
                                          : `backgroundFix`
                                      }
                                    >
                                      <ToasterNotifications
                                        showClass="toasterNotificationWrapper1"
                                        data-testid="toaster-notification"
                                      />
                                    </div> */}
                                          <Suspense
                                            fallback={
                                              <ReusableLoader
                                                isComponentBody={true}
                                                isOverlay={true}
                                              />
                                            }
                                          >
                                            {o.component}
                                          </Suspense>
                                          <NotificationPanel />
                                        </div>
                                      </div>
                                    ) : (
                                      <ReusableLoader
                                        isAppBody={true}
                                        isOverlay={true}
                                      />
                                    )}
                                  </>
                                }
                                key={index}
                              />
                            );
                          })}

                          {routesObject.map((o, index) => {
                            return (
                              <Route
                                path={o.route}
                                element={
                                  <>
                                    {userInfo.loggedInWeb == true && <NavBar />}
                                    <TopBar context={props.context} />
                                    {!userInfo.loading ? (
                                      <div
                                        className={`teamAppContainer ${
                                          toggleHamburger
                                            ? "teamAppContainer_lg"
                                            : "teamAppContainer_sm"
                                        }  ${
                                          showSideNavbar ? "" : " hideSidebar"
                                        }`}
                                        style={
                                          !toggleHamburger &&
                                          notifications.isFullPageNotification
                                            ? { gridTemplateColumns: "1px 1fr" }
                                            : {}
                                        }
                                      >
                                        <Sidebar />
                                        <div
                                          className={`sideBody ${
                                            toggleHamburger ? "menuOverlay" : ""
                                          }`}
                                        >
                                          <div
                                            id="adjustToaster"
                                            className={
                                              notificationMsgsData.showMsgBar ===
                                              true
                                                ? `common_container_header1 showToaster ${
                                                    path === "yourchannel"
                                                      ? " userManagementDialogToaster"
                                                      : ""
                                                  }`
                                                : `backgroundFix`
                                            }
                                          >
                                            <ToasterNotifications
                                              showClass="toasterNotificationWrapper1"
                                              data-testid="toaster-notification"
                                            />
                                          </div>
                                          <CommonLayout options={o.options}>
                                            <Suspense
                                              fallback={
                                                <ReusableLoader
                                                  isComponentBody={true}
                                                  isOverlay={true}
                                                />
                                              }
                                            >
                                              {o.component}
                                            </Suspense>
                                          </CommonLayout>
                                          <NotificationPanel />
                                        </div>
                                      </div>
                                    ) : (
                                      <ReusableLoader
                                        isAppBody={true}
                                        isOverlay={true}
                                      />
                                    )}
                                  </>
                                }
                                key={index}
                              />
                            );
                          })}

                          {detailsRoutes.map((o, index) => {
                            return (
                              <Route
                                path={o.route}
                                element={
                                  <>
                                    <div
                                      className={`sideBody fullBody ${
                                        toggleHamburger ? "menuOverlay" : ""
                                      }`}
                                    >
                                      <div
                                        id="adjustToaster"
                                        className={
                                          notificationMsgsData.showMsgBar ===
                                          true
                                            ? `common_container_header1 showToaster  ${
                                                path === "yourchannel"
                                                  ? " userManagementDialogToaster"
                                                  : ""
                                              }`
                                            : `backgroundFix`
                                        }
                                      >
                                        <ToasterNotifications
                                          showClass="toasterNotificationWrapper1"
                                          data-testid="toaster-notification"
                                        />
                                      </div>
                                      <CommonLayout options={o.options}>
                                        <Suspense
                                          fallback={
                                            <ReusableLoader
                                              isComponentBody={true}
                                              isOverlay={true}
                                            />
                                          }
                                        >
                                          {o.component}
                                        </Suspense>
                                      </CommonLayout>
                                      <NotificationPanel />
                                    </div>
                                  </>
                                }
                                key={index}
                              />
                            );
                          })}
                          <Route
                            path={"/unauthorized"}
                            element={<UnauthorizedPage />}
                            key={0}
                          />
                          {userInfo.loggedInWeb == true && (
                            <Route
                              path={"/logout"}
                              element={
                                <>
                                  <NavBar loggedOut={true} />
                                  <LogoutPage />
                                </>
                              }
                              key={0}
                            />
                          )}
                        </Routes>
                      </>
                    )
                }
                {userInfo.newUser && <WelcomePage />}
                {!userInfo.isAuthorized ? (
                  <UnauthorizedPage />
                ) : (
                  userInfo.apiFailed &&
                  (userInfo.allCompanies.length > 0 ? (
                    <UnauthorizedWithTopBar context={props?.context} />
                  ) : (
                    <ConfirmationPage
                      heading="Thank you for your patience!"
                      image={require("./assets/GenericError.png")}
                      content={userInfo.apiFailureMessage}
                    />
                  ))
                )}
                {/* {!isUserAllowed && authCheckDone && <UnauthorizedPage />} */}
                {
                  // isUserAllowed &&
                  //   authCheckDone &&
                  !userInfo.apiFailed &&
                    !userInfo.loading &&
                    userInfo?.teams?.privacyAgreementDeclined &&
                    userInfo.isAuthorized && (
                      <UnauthorizedWithTopBar context={props?.context} />
                    )
                }
                {
                  // isUserAllowed &&
                  //   authCheckDone &&
                  !userInfo.newUser &&
                    !userInfo.apiFailed &&
                    !userInfo.loading &&
                    !userInfo?.teams?.privacyAgreementAccepted &&
                    !userInfo?.teams?.privacyAgreementDeclined && (
                      <Suspense
                        fallback={
                          <ReusableLoader
                            isComponentBody={true}
                            isOverlay={true}
                          />
                        }
                      >
                        <PrivacyNoticePopup />
                      </Suspense>
                    )
                }
              </>
            ))}
          {!isAuthenticated && userInfo?.loggedInWeb && (
            <Routes>
              <Route
                path={"/logout"}
                element={
                  <>
                    <NavBar loggedOut={true} />
                    <LogoutPage />
                  </>
                }
                key={0}
              />
            </Routes>
          )}
          <Routes>
            <Route
              path={"/register"}
              element={
                <>
                  <RegisterPage />
                </>
              }
              key={0}
            />
          </Routes>
        </>
      )}
    </>
  );
}

const TIMEOUT_DURATION = 30 * 60 * 1000; // 30 minutes
function useSessionTimeout() {
  const { instance } = useMsal();
  const { userInfo } = UseUserInfoContext();

  useEffect(() => {
    if (userInfo?.loggedInWeb === true) {
      //console.log("Timeout: ", TIMEOUT_DURATION);
      let timeoutId = setTimeout(() => {
        deleteData("", APIEndPoints.Delete_EY_TOKEN).then(() => {
          const keyToKeep = "curUserSelectedCompany";
          const keys = Object.keys(localStorage);
          keys.forEach((key) => {
            if (key !== keyToKeep) {
              localStorage.removeItem(key);
            }
          });
          instance.logoutRedirect();
        });
      }, TIMEOUT_DURATION);

      const resetTimeout = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          deleteData("", APIEndPoints.Delete_EY_TOKEN).then(() => {
            const keyToKeep = "curUserSelectedCompany";
            const keys = Object.keys(localStorage);
            keys.forEach((key) => {
              if (key !== keyToKeep) {
                localStorage.removeItem(key);
              }
            });
            instance.logoutRedirect();
          });
        }, TIMEOUT_DURATION);
      };

      window.addEventListener("mousemove", resetTimeout);
      window.addEventListener("mousedown", resetTimeout);
      window.addEventListener("keypress", resetTimeout);
      window.addEventListener("scroll", resetTimeout);
      window.addEventListener("touchmove", resetTimeout);

      return () => {
        clearTimeout(timeoutId);
        window.removeEventListener("mousemove", resetTimeout);
        window.removeEventListener("mousedown", resetTimeout);
        window.removeEventListener("keypress", resetTimeout);
        window.removeEventListener("scroll", resetTimeout);
        window.removeEventListener("touchmove", resetTimeout);
      };
    }
  }, [instance, userInfo.loggedInWeb]);
}

export default App;
