import React, { useState, useEffect } from "react";
import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import APIEndPoints from "../../utility/apiendpoints";
import { postData, getDataWithoutAuth } from "../../services/customApis";
import {
  Viewer,
  Worker,
  ScrollMode,
  Button,
  Spinner,
} from "@react-pdf-viewer/core";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";

function StaticPageContainer(props) {
  const [error, SetError] = useState(false);
  const [errorMsg, SetErrorMsg] = useState("");
  const [b64String, setBase64String] = useState("");
  const [url, setUrl] = useState("");
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [divStyle, setDivStyle] = useState({});

  useEffect(() => {
    const getPDFDataDetails = async () => {
      getDataWithoutAuth(props.apiName).then((fileDetails) => {
        let base64String =
          fileDetails.data.fileName != undefined
            ? fileDetails.data.fileStream
            : setIsPdfLoading(true);

        let newUrl = "data:application/pdf;base64," + base64String;
        const blobData = base64toBlob(newUrl);
        const url = URL.createObjectURL(blobData);
        setBase64String(base64String);
        setUrl(url);
        setIsPdfLoading(false);
      });
    };
    getPDFDataDetails();
    document.title = props.tabName;
  }, []);

  useEffect(() => {
    const handleHeight = (ev) => {
      let height = window.innerHeight;
      let heightVal = height + "px";
      setDivStyle({
        border: "1px solid rgba(0, 0, 0, 0.3)",
        display: "flex",
        flexDirection: "column",
        height: heightVal,
      });
    };

    try {
      window.addEventListener("resize", handleHeight, true);
    } catch (e) {
      console.log(e);
    }
    return () => {
      window.removeEventListener("resize", handleHeight, true);
    };
  }, []);

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr(
      "data:application/pdf;base64,".length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };

  return (
    <>
      {isPdfLoading === true ? (
        <ReusableLoader />
      ) : (
        <Provider theme={teamsTheme}>
          <div data-testid="pdfContainer" className="privacy-pdf-container">
            {/* <div style={divStyle} data-testid="pdfContainer"> */}
            <div
              style={{
                alignItems: "flex-end",
                display: "flex",
                padding: "4px",
                marginLeft: "auto",
              }}
              className="btn-container"
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <div
                  style={{
                    flex: 1,
                    overflow: "hidden",
                  }}
                  data-testid="pdf-Containerdiv-testid"
                  className="pdf-container-custom"
                >
                  <Viewer fileUrl={url} />
                </div>
              </Worker>
              {/* </div> */}
            </div>
          </div>
        </Provider>
      )}
    </>
  );
}

export default StaticPageContainer;
