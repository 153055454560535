import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsXLg } from "react-icons/bs";
import { AiFillExclamationCircle } from "react-icons/ai";

import { UseNotificationContext } from "../../context/notificationcontext/notificationContext";
import {
  useHamburgerMenuContext,
  useMenuContext,
} from "../../context/menucontext/menuContext";
import { UseContentCurationContext } from "../../context/contentcurationcontext/contentCurationContext";
import { UseContactUsContext } from "../../context/contactuscontext/contactUsContext";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import FooterTechButtons from "../../commonui/commonformcomponents/footertechbuttons/FooterTechButtons";
import Label from "../../commonui/commonformcomponents/label/Label";
import TextBox from "../../commonui/commonformcomponents/textbox/TextBox";
import TextArea from "../../commonui/commonformcomponents/textarea/TextArea";
import DropdownComponent from "../../commonui/commonformcomponents/dropdown/dropdown";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import Alert from "../../commonui/commonformcomponents/alert/alert";
import UnauthorizedPage from "../../commonui/unauthorizedpage/unauthorized";
import { useLocation } from "react-router-dom";
import { getData, getDataById, postData } from "../../services/customApis";
import { TrackEvent } from "../../services/appInsights";
import APIEndPoints from "../../utility/apiendpoints";
import Constant from "../../utility/constants";
import {
  showLayover,
  hideLayover,
  getContentTypeNamesById,
} from "../../utility/utility";
import TelemetryEventNames from "../../data/apptelemetry/appTelemetry.const";
import "./contactUs.css";

function ContactUs() {
  const location = useLocation();
  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
  const { handleNotifications } = UseNotificationContext();
  const { menuState } = useMenuContext();
  const { contentCurationData } = UseContentCurationContext();
  let { userInfo } = UseUserInfoContext();
  const { menuInfo, handleMenuInfo } = UseContactUsContext();
  const navigate = useNavigate();
  const [subject, setSubject] = useState("");
  const [issueDescription, setIssueDescription] = useState("");
  const [sections, setSections] = useState([]);
  const [subSections, setSubSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState();
  const [selectedSubSection, setSelectedSubSection] = useState();
  const [sectionFlag, setSectionFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorState, setErrorState] = useState({
    Subject: {},
    Section: {},
    SubSection: {},
    Description: {},
  });
  const [alertContent, setAlertContent] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [errorAccessDenied, setAccessDenied] = useState(false);
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();

  useEffect(() => {
    handleNotifications({
      isFullPageNotification: true,
    });
    window.scrollTo(0, 0);
    if (toggleHamburger) {
      handleHamburgerChange();
    }
    Object.keys(errorState).forEach((key) => {
      updateValidationState(errorState[key], "", false, "");
    });
    const getContactUsDetails = async () => {
      let menuName = "",
        subMenuName = "";
      let submenuForChildEntity = "";
      if (
        menuInfo.menuName === "Settings" ||
        menuInfo.menuName === "What's Catalyst" ||
        menuInfo.menuName === "Search" ||
        menuInfo.menuName === "Your Channel" ||
        menuInfo.menuName === "Channel Management"
      ) {
        menuName = menuInfo.menuName;
        subMenuName = menuInfo.subMenuName;
      } else {
        menuName = menuState.menu.name;
        subMenuName = menuState.submenu.name;
      }
      if (menuInfo.menuName === "Channel Management") {
        submenuForChildEntity = "Child Entity creation request";
      }
      if (menuName === "Apps" && !subMenuName) {
        subMenuName = "Others";
      }
      if (menuName === "EY App Catalog" && !subMenuName) {
        subMenuName = "Others";
      }

      return await getData(APIEndPoints.GET_CONTACTUS_DETAILS).then(
        (response) => {
          if (response?.status === 200) {
            const dropdownSections = [];
            const dropdownSubSections = [];
            let filteredSections;
            let filteredSubSections;
            // let excludedSubContentType = ["Polls"];

            response?.data?.contactUsSections?.forEach((item) => {
              if (
                item.sectionName === "Channel Management" &&
                userInfo?.teams?.companyName !== "EY Global Only"
              ) {
                return; // Skip this iteration
              }

              let optionMenu = {
                key: item.sectionId,
                header: item.sectionName,
              };
              dropdownSections.push(optionMenu);
              if (
                item.sectionName?.toLowerCase().trim() ===
                menuName?.toLowerCase()
              ) {
                filteredSections = optionMenu;
              }
            });

            // const subcontenttype = response?.data?.contactUsSubSections.filter(
            //   (item) => !excludedSubContentType.includes(item.subSectionName)
            // );
            response?.data?.contactUsSubSections?.forEach((item) => {
              let optionsSubMenu = {
                key: item.subSectionId,
                header: item.subSectionName,
                sectionId: item.sectionId,
              };
              dropdownSubSections.push(optionsSubMenu);
              if (
                filteredSections?.key === item.sectionId &&
                item.subSectionName?.toLowerCase() ===
                  subMenuName?.toLowerCase()
              ) {
                filteredSubSections = optionsSubMenu;
              }
              if (
                filteredSections?.key === item.sectionId &&
                item.subSectionName?.toLowerCase() ===
                  submenuForChildEntity?.toLowerCase()
              ) {
                filteredSubSections = optionsSubMenu;
              }
              //Commented for now as we are not using this code specifically for apps and internalApps
              // if (
              //   filteredSections?.key === item.sectionId &&
              //   filteredSections?.header?.toLowerCase() ===
              //   "Apps"?.toLowerCase()
              // ) {
              //   filteredSubSections = optionsSubMenu;
              // }
              if (
                filteredSections?.key === item.sectionId &&
                filteredSections?.header?.toLowerCase().trim() ===
                  "Bulletin Board"?.toLowerCase()
              ) {
                filteredSubSections = optionsSubMenu;
              }
              if (
                filteredSections?.key === item.sectionId &&
                filteredSections?.header?.toLowerCase().trim() ===
                  "Your Channel"?.toLowerCase()
              ) {
                filteredSubSections = optionsSubMenu;
              }
            });
            setSections(dropdownSections);
            setSubSections(dropdownSubSections);
            setSelectedSection(filteredSections);
            setSelectedSubSection(filteredSubSections);
            setErrorState({
              ...errorState,
              ["Section"]: {
                ...errorState["Section"],
                currentValue: filteredSections,
              },
              ["SubSection"]: {
                ...errorState["SubSection"],
                currentValue: filteredSubSections,
              },
            });
          }
        }
      );
    };

    getDataById(
      "",
      APIEndPoints.GET_PAGE_LEVEL_FLAGS(userInfo?.teams?.companyId, 36)
    )
      .then(async (res) => {
        getContactUsDetails().then(() => {
          setIsLoading(false);
        });
      })
      .catch(async (error) => {
        if (
          error?.response?.data?.Errors?.[0]?.toLowerCase() == "access denied."
        ) {
          setAccessDenied(true);
          setIsLoading(false);
        }
      });

    return () => {
      handleNotifications({
        isFullPageNotification: false,
      });
    };
  }, []);

  useEffect(() => {
    if (sectionFlag === true) {
      let changedSubSections = subSections.filter((item) => {
        return item.sectionId === selectedSection?.key;
      });
      setSelectedSubSection(changedSubSections[0]);
      setSectionFlag(false);
      updateValidationState("SubSection", changedSubSections[0], false, "");
    }
  }, [sectionFlag]);

  //channel Management related changes
  useEffect(() => {
    if (
      menuInfo &&
      menuInfo.sourceComponent === "requestChannel" &&
      menuInfo.issueDescription
    ) {
      const { clientId, clientName, dunsNumber } = menuInfo.issueDescription;
      const formattedDescription = `Client ID: ${clientId}\n\nClient Name: ${clientName}\n\nDuns: ${dunsNumber}`;
      setIssueDescription(formattedDescription);
      // Update the validation state for the issue description
      updateValidationState("Description", formattedDescription, false, "");
    }
  }, [menuInfo]);

  const handleCloseContactUs = () => {
    if (!toggleHamburger) {
      if (window.innerWidth > 900) {
        handleHamburgerChange();
      }
    }
    if (
      menuInfo.menuName === "Search" &&
      menuInfo?.resourceType?.contentId > 0
    ) {
      handleMenuInfo({ isClickedClose: true, sourceComponent: "" });
      setTimeout(() => {
        navigate(-1);
      }, 1000); // 1 second delay
    } else if (menuInfo.menuName === "Apps") {
      navigate("/apps/LibrarySearchPage", {
        state: {
          appDetails: location?.state?.appDetails,
          privilagesPowerKApp: location?.state?.privilagesPowerKApp,
          templateDetails: location?.state?.templateDetails,
        }
      });
    } else {
      navigate(-1);
    }
    // navigate(`${contentCurationData?.pageId}`);
  };

  const onSubjectChange = (
    e,
    name,
    controlName,
    cf,
    cof,
    pn,
    hasError,
    errMessage
  ) => {
    setSubject(e.target.value);
    updateValidationState(controlName, e.target.value, hasError, errMessage);
  };

  const onIssueDescriptionChange = (
    e,
    name,
    controlName,
    cf,
    cof,
    pn,
    hasError,
    errMessage
  ) => {
    const newIssueDescription = e.target.value;
    setIssueDescription(newIssueDescription);
    // Update the validation state to reflect the user's input
    const hasDescriptionError = !newIssueDescription; // Set to true if newIssueDescription is empty
    updateValidationState(
      "Description",
      newIssueDescription,
      hasDescriptionError,
      hasDescriptionError ? "This information is required" : ""
    );
  };
  const onChangeSection = (e, fn, name, cf, pn, hasError, errMessage) => {
    setSelectedSection(e);
    setSectionFlag(true);
    updateValidationState(name, e, hasError, errMessage);
  };

  const onChangeSubSection = (e, fn, name, cf, pn, hasError, errMessage) => {
    setSelectedSubSection(e);
    updateValidationState(name, e, hasError, errMessage);
  };
  const onSubmitContactUs = async () => {
    let isValidated = true;
    let currentErrorState = { ...errorState };
    Object.keys(currentErrorState).forEach((key) => {
      const value = currentErrorState[key]["currentValue"];
      const fieldHasError = currentErrorState[key]["hasAnyError"];
      if (currentErrorState[key]["hasAnyError"] === true) {
        isValidated = false;
      }
      if (
        (currentErrorState[key]["currentValue"] == null ||
          currentErrorState[key]["currentValue"] == undefined ||
          currentErrorState[key]["currentValue"] == "" ||
          currentErrorState[key]["currentValue"].length == 0) &&
        key !== "Description"
      ) {
        isValidated = false;
        currentErrorState[key]["hasAnyError"] = true;
        currentErrorState[key]["errorMessage"] = "This information is required";
      }
    });

    // Special validation for issueDescription to ensure it's not empty
    if (!issueDescription.trim()) {
      isValidated = false;
      currentErrorState["Description"] = {
        ...currentErrorState["Description"],
        hasAnyError: true,
        errorMessage: "Issue description is required",
      };
    }

    if (isValidated === false) {
      setErrorState({ ...currentErrorState });
    } else {
      let contactusDetails = userInfo.teams.companyFeatureFlags.find(
        (value) => {
          return (
            value?.featureFlagName.toLowerCase() === "Contact Us".toLowerCase()
          );
        }
      );
      setIsLoading(true);
      showLayover();
      let contactUsPayload = {
        sectionId: selectedSection?.key,
        sectionName: selectedSection?.header?.toString(),
        subSectionId: selectedSubSection?.key,
        subSectionName: selectedSubSection?.header?.toString(),
        subject: subject?.toString(),
        issueDescription: issueDescription?.toString(),
      };
      await postData(
        contactUsPayload,
        APIEndPoints.SUBMIT_CONTACTUS_DETAILS(
          userInfo?.teams?.key,
          contactusDetails?.subMenuItemId
        ),
        false
      )
        .then((response) => {
          console.log(response);
          hideLayover();
          setIsLoading(false);
          if (response?.status === 200) {
            handleCloseContactUs();
            setTimeout(() => {
              handleNotificationMsgsData({
                showMsgBar: true,
                started: false,
                completed: true,
                msg: response?.data,
                type: "Success",
                isFailed: false,
              });
            }, 1000);
            //TESTED
            TrackEvent(
              TelemetryEventNames.UCC_OTHER_CONTACT_US_EVENT,
              userInfo,
              {
                SectionName: TelemetryEventNames.SECTION_TYPE_OTHER,
                SectionName: contactUsPayload.sectionName ?? "",
                SubSectionName: contactUsPayload.subSectionName ?? "",
              }
            );
          } else {
            setShowAlert(true);
            setAlertContent(response?.Errors[0]);
            setTimeout(() => {
              setShowAlert(false);
              setAlertContent("");
            }, 3000);
          }
        })
        .catch((error) => {
          let errorText = error?.response?.data?.Errors?.[0];
          console.log(error);
          hideLayover();
          setIsLoading(false);
          setShowAlert(true);
          setAlertContent(errorText ? errorText : Constant.CONTACTUS_FAILURE);
          setTimeout(() => {
            setShowAlert(false);
            setAlertContent("");
          }, 3000);
        });
    }
  };

  const updateValidationState = (name, value, hasAnyError, errMessage) => {
    let updatedObj = {};
    let obj = {
      currentValue: value,
      hasAnyError: hasAnyError,
      errorMessage: errMessage,
    };
    updatedObj = {
      ...errorState,
      [name]: obj,
    };
    setErrorState({ ...updatedObj });
  };

  return (
    <>
      {isLoading === true && (
        <ReusableLoader isOverlay={true} isAppBody={true} />
      )}
      <>
        <div className="common_container_header contactUsHeader">
          {showAlert && (
            <Alert
              content={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AiFillExclamationCircle />
                  {alertContent}
                </div>
              }
              visible={showAlert}
              danger={true}
              dismissible={true}
              onClickDismiss={() => {
                setShowAlert(false);
              }}
            />
          )}
          <header>
            <p className="common_title">
              {"Contact Us"}
              <BsXLg
                title="Close"
                className="contactus-close"
                onClick={() => handleCloseContactUs()}
              />
            </p>
          </header>
        </div>
        {errorAccessDenied ? (
          <UnauthorizedPage />
        ) : (
          <>
            <div className="contactUsBody">
              <div className={`row_wrapper`}>
                <Label
                  content={"How can we help you?"}
                  checkIfRequired={true}
                />
                <TextBox
                  placeholder={"Type a brief description of the issue"}
                  required={true}
                  onChange={onSubjectChange}
                  value={subject}
                  validations={[
                    {
                      name: "MaxLength",
                      maxChar: 100,
                      errorMessage: "Length cannot be more than 100",
                    },
                  ]}
                  name={"Subject"}
                  hasAnyError={errorState?.["Subject"]?.hasAnyError}
                  errMessage={errorState?.["Subject"]?.errorMessage}
                />
              </div>
              <div className={`row_wrapper section_wrapper`}>
                <div>
                  <Label content={"Section"} checkIfRequired={true} />
                  <DropdownComponent
                    items={sections}
                    onDropDownChange={onChangeSection}
                    defaultCheckedValue={selectedSection}
                    multiple={false}
                    // placeholder={"Select Section"}
                    initialLoad={false}
                    name={"Section"}
                    validations={[
                      {
                        name: "IsValidValue",
                        errorMessage: "Section value is mandatory",
                      },
                    ]}
                    className={"sectionDropdown"}
                    hasAnyError={errorState?.["Section"]?.hasAnyError}
                    errMessage={errorState?.["Section"]?.errorMessage}
                    value={selectedSection}
                  />
                </div>
                <div>
                  <Label content={"Sub-Section"} checkIfRequired={true} />
                  <DropdownComponent
                    items={subSections.filter((item) => {
                      return item.sectionId === selectedSection?.key;
                    })}
                    onDropDownChange={onChangeSubSection}
                    defaultCheckedValue={selectedSubSection}
                    multiple={false}
                    // placeholder={"Select Sub-Section"}
                    initialLoad={false}
                    name={"SubSection"}
                    validations={[
                      {
                        name: "IsValidValue",
                        errorMessage: "Sub-Section value is mandatory",
                      },
                    ]}
                    className={"subSectionDropdown"}
                    hasAnyError={errorState?.["SubSection"]?.hasAnyError}
                    errMessage={errorState?.["SubSection"]?.errorMessage}
                    value={selectedSubSection}
                  />
                </div>
              </div>
              <div className={`row_wrapper`}>
                <Label content={"Issue Description"} checkIfRequired={true} />
                <TextArea
                  placeholder={"Type detailed description of the issue"}
                  required={true}
                  height={"120px"}
                  onChange={onIssueDescriptionChange}
                  isContactUs={true}
                  value={issueDescription}
                  validations={[
                    {
                      name: "MaxLength",
                      maxChar: 2000,
                      errorMessage: "Length cannot be more than 2000",
                    },
                  ]}
                  name={"Description"}
                  hasAnyError={errorState?.["Description"]?.hasAnyError}
                  errMessage={errorState?.["Description"]?.errorMessage}
                />
              </div>
            </div>
            <div
              className={
                !toggleHamburger ? "formFooter" : "formFooter fullwidth"
              }
            >
              <FooterTechButtons
                disabled={false}
                cancelText={"Cancel"}
                sendText={"Submit"}
                onClickAddData={onSubmitContactUs}
                onClickCancel={handleCloseContactUs}
              />
            </div>
          </>
        )}
      </>
    </>
  );
}

export default ContactUs;
