import React, { useState, useEffect } from "react";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import APIEndPoints from "../../utility/apiendpoints";
import Constant from "../../utility/constants";
import { postData } from "../../services/customApis";
import Button from "../../commonui/button/button";
import ButtonPrimary from "../../commonui/button/buttonPrimary";
import { app } from "@microsoft/teams-js";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import StaticPageContainer from "../../components/staticpagesforcatalystapp/staticPageContainer";
import "./privacyNoticePopup.css";

function PrivacyNoticePopup(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, SetError] = useState(false);
  const [errorMsg, SetErrorMsg] = useState("");
  const [divStyle, setDivStyle] = useState({});
  const { userInfo, handleUserInfoChange } = UseUserInfoContext();
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(userInfo.isAuthorized);

  const hideModal = () => {
    setIsModalOpen(!isModalOpen);
    handleUserInfoChange({ teams: { privacyAgreementDeclined: true } });
    props?.setOpenDialog(false);
  };

  useEffect(() => {
    const handleHeight = (ev) => {
      let height = window.innerHeight;
      let heightVal = height + "px";
      setDivStyle({
        border: "1px solid rgba(0, 0, 0, 0.3)",
        display: "flex",
        flexDirection: "column",
        height: heightVal,
      });
    };

    try {
      window.addEventListener("resize", handleHeight, true);
    } catch (e) {
      console.log(e);
    }
    return () => {
      window.removeEventListener("resize", handleHeight, true);
    };
  }, []);

  const AcceptPrivacyAgreement = async () => {
    console.log("Privacy notice accepted.");
    let newInputObj = {};
    let defaultComp = userInfo.allCompanies?.[0];
    handleUserInfoChange({ loading: true });
    setIsModalOpen(false);
    await postData(
      newInputObj,
      APIEndPoints.ACCEPT_PRIVACY_AGREEMENT(
        userInfo.teams.companyId ?? defaultComp?.companyId
      )
    )
      .then((item) => {
        handleUserInfoChange({ loading: true, privacyAgreementAccepted: true });
        window.localStorage.setItem(
          "curUserSelectedCompany",
          JSON.stringify(
            userInfo.teams.companyId
              ? {
                  ...userInfo.teams,
                  privacyAgreementDeclined: false,
                  privacyAgreementAccepted: true,
                }
              : {
                  ...defaultComp,
                  key: defaultComp.companyId,
                  text: defaultComp.companyName,
                  schema: defaultComp.schema,
                  companyFeatureFlags: defaultComp.companyFeatureFlags,
                  isSelected: defaultComp.isSelected,
                  privacyAgreementDeclined: false,
                  privacyAgreementAccepted: true,
                }
          )
        );
        let privacyAgreementAccepted = userInfo.allCompanies?.some(
          (obj) => obj?.privacyAgreementAccepted === true
        );

        window.location.href =
          Constant.REDIRECTION_PATH +
          (privacyAgreementAccepted ? "" : "?showTaketheTour=true");
        // }
      })
      .catch((err) => {
        SetError(true);
        setIsLoading(false);
        SetErrorMsg(err?.response?.data?.Errors);
        props?.setOpenDialog(false);
      });
  };

  useEffect(() => {
    if (userInfo.teams.companyId && !userInfo.teams.privacyAgreementAccepted) {
      setIsModalOpen(true);
    }
  }, [userInfo.teams.companyId]);

  return (
    <DialogBox
      closeOnOutsideClick={false}
      backdrop={true}
      openDialog={isModalOpen}
      className={"dialogStyles datadropheader"}
      footer={true}
      headerName={"Privacy Notice"}
      content={
        <div>
          <div>
            {userInfo?.teams?.isInternalCompany ? (
              <div className="justifyContent">
                Your contact information will be used to authenticate you into
                the tool and will be handled in accordance with{" "}
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() =>
                    userInfo.loggedInWeb === true
                      ? window.open(
                          "https://www.ey.com/en_gl/privacy-statement",
                          "_blank"
                        )
                      : app.openLink(
                          "https://www.ey.com/en_gl/privacy-statement"
                        )
                  }
                >
                  {" "}
                  EY's Privacy Statement
                </a>
              </div>
            ) : // <div className="justifyContent">
            //     <p style={{ fontWeight: "600" }}>
            //       We are excited for you to participate in the BETA testing of
            //       EY Catalyst Connect (the “BETA”). EY and Microsoft partnered
            //       to create a Microsoft Teams application that will give you
            //       access to the information and insight you need to share with
            //       your team and EY to help make strategic business decisions.
            //       The term of this BETA testing will be from the date of your
            //       acceptance of these TERMS OF USE, until Friday, March 15,
            //       2024.
            //       <br />
            //       <br />
            //       We value your feedback. We would like to provide you with
            //       early access to EY Catalyst Connect before its official
            //       release. You will have direct access to our development team
            //       to provide feedback to help shape the development of this
            //       innovative technology.
            //       <br />
            //       <br />
            //       Please note as this is strictly an evaluation, the BETA is
            //       provided “as-is,” and none of EY or any other party involved
            //       in the creation, production or delivery of the BETA makes any
            //       warranties, of any kind of nature, whether express or implied,
            //       with respect to the BETA, including but not limited to any
            //       warranty that the operation of BETA will be uninterrupted,
            //       error free or that it will be compatible with any of your
            //       hardware or software or any warranties for merchantability or
            //       fitness for a particular purpose or use or warranties of any
            //       products or services.
            //       <br />
            //       <br />
            //       By using EY Catalyst Connect, you agree to the privacy policy
            //       and Terms of Use (below). The BETA is not intended to provide
            //       you with a service that can be relied on or used, internally
            //       or otherwise. You agree that the content of the BETA version
            //       does not contain any tax technical or other professional
            //       advice and should not be seen as such. To the extent you wish
            //       to generate any calculations in order to evaluate the
            //       capabilities and functionality of the tool, please limit any
            //       data you may input into Catalyst to only such information
            //       contained in previously filed tax returns. Your use of EY
            //       Catalyst Connect during the BETA is made entirely at your own
            //       risk and responsibility. Neither party is obliged to proceed
            //       with any purchase or enter any further agreement of any kind
            //       in respect of Catalyst beyond the end of the BETA.
            //       <br />
            //       <br />
            //       If you do provide us with any suggestions or recommendations
            //       about changes to the BETA, including without limitation, new
            //       features or functionality relating thereto, or any comments,
            //       questions, suggestions, or the like ("Feedback"), you
            //       acknowledge and agree that EY is free to use such Feedback
            //       irrespective of any other obligation or limitation between the
            //       parties governing such Feedback. You hereby assign to EY, and
            //       on behalf of its personnel, contractors and/or agents, all
            //       right, title, and interest in, and EY is free to use, without
            //       any attribution or compensation to any party, any ideas,
            //       know-how, concepts, techniques, or other intellectual property
            //       rights contained in the Feedback, for any purpose whatsoever,
            //       although EY is not required to use any Feedback. EY shall not
            //       externally disclose the Feedback in non-anonymized form or use
            //       the Feedback in a way that identifies.
            //       <br />
            //       <br />
            //     </p>
            //     <p style={{ display: "flex", gap: "10px" }}>
            //       <span style={{ marginTop: "3px" }}>
            //         <input
            //           type="checkbox"
            //           style={{
            //             height: "18px",
            //             width: "18px",
            //             cursor: "pointer",
            //           }}
            //           checked={checkBoxChecked}
            //           onChange={(e) => setCheckBoxChecked(e.target.checked)}
            //         />
            //       </span>
            //       <span>
            //         By clicking on 'Accept', you are confirming that you have
            //         read and agree to EY Catalyst Connect's{" "}
            //         <a
            //           style={{
            //             color: "blue",
            //             cursor: "pointer",
            //             textDecoration: "underline",
            //           }}
            //           onClick={() =>
            //             userInfo.loggedInWeb === true
            //               ? window.open(
            //                   "https://www.ey.com/en_gl/legal-statement",
            //                   "_blank"
            //                 )
            //               : app.openLink(
            //                   "https://www.ey.com/en_gl/legal-statement"
            //                 )
            //           }
            //         >
            //           Terms of Use
            //         </a>{" "}
            //         and{" "}
            //         <a
            //           style={{
            //             color: "blue",
            //             cursor: "pointer",
            //             textDecoration: "underline",
            //           }}
            //           onClick={() =>
            //             userInfo.loggedInWeb === true
            //               ? window.open(
            //                   "https://www.ey.com/en_gl/legal-and-privacy",
            //                   "_blank"
            //                 )
            //               : app.openLink(
            //                   "https://www.ey.com/en_gl/legal-and-privacy"
            //                 )
            //           }
            //         >
            //           Privacy policy.
            //         </a>
            //       </span>
            //     </p>
            //     <br />
            //     <p style={{ fontWeight: "600" }}>
            //       We look forward to working with you.
            //       <br />
            //       Sincerely, EY
            //     </p>
            //   </div>

            !userInfo?.isInternal ? (
              <div className="justifyContent">
                <br></br>
                Review{" "}
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() =>
                    userInfo.loggedInWeb === true
                      ? window.open(
                          "https://login.ey.com/myey/privacy-notice",
                          "_blank"
                        )
                      : app.openLink("https://login.ey.com/myey/privacy-notice")
                  }
                >
                  {" "}
                  My EY Privacy Notice
                </a>{" "}
                and click OK to proceed.
                <br></br>
              </div>
            ) : (
              <div className="justifyContent">
                <StaticPageContainer
                  apiName={"/PrivacyNotice/getPrivacyNotice"}
                  tabName={"EY Catalyst Connect"}
                />
                <p style={{ display: "flex", gap: "10px" }}>
                  <span style={{ marginTop: "3px" }}>
                    <input
                      type="checkbox"
                      style={{
                        height: "18px",
                        width: "18px",
                        cursor: "pointer",
                      }}
                      checked={checkBoxChecked}
                      onChange={(e) => setCheckBoxChecked(e.target.checked)}
                    />
                  </span>
                  <span>
                    By clicking on 'Accept', you are confirming that you have
                    read and agree to EY Catalyst Connect's{" "}
                    <a
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() =>
                        userInfo.loggedInWeb === true
                          ? window.open(
                              "https://www.ey.com/en_gl/legal-statement",
                              "_blank"
                            )
                          : app.openLink(
                              "https://www.ey.com/en_gl/legal-statement"
                            )
                      }
                    >
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() =>
                        userInfo.loggedInWeb === true
                          ? window.open(
                              "https://www.ey.com/en_gl/legal-and-privacy",
                              "_blank"
                            )
                          : app.openLink(
                              "https://www.ey.com/en_gl/legal-and-privacy"
                            )
                      }
                    >
                      Privacy policy.
                    </a>
                  </span>
                </p>
                <br />
                <p style={{ fontWeight: "600" }}>
                  We look forward to working with you.
                  <br />
                  Sincerely, EY
                </p>
              </div>
            )}
          </div>
          <>
            {
              <>
                {" "}
                <div>
                  <div className="btncontainer">
                    <ButtonPrimary
                      text={`${!userInfo?.isInternal ? "OK" : "Accept"}`}
                      onClick={AcceptPrivacyAgreement}
                      disabled={
                        userInfo?.teams?.isInternalCompany ||
                        !userInfo?.isInternal
                          ? false
                          : !checkBoxChecked
                      }
                    />

                    <Button
                      text={`${"Decline"}`}
                      onClick={hideModal}
                      className={!userInfo?.isInternal && "hideButton"}
                    />
                  </div>
                </div>
              </>
            }
          </>
        </div>
      }
    />
  );
}

export default PrivacyNoticePopup;
