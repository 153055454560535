import React, { useEffect, useState } from "react";
import { UseUserInfoContext } from "../../../context/usercontext/userContext";
import { useHamburgerMenuContext } from "../../../context/menucontext/menuContext";
import { useNavigate } from "react-router-dom";
import TextBox from "../../../commonui/commonformcomponents/textbox/TextBox";
import MultiSelectReactDropdown from "../../../commonui/commonformcomponents/multiselectreactdropdown/MultiSelectReactDropdown";
import DatePicker from "../../../commonui/commonformcomponents/datepicker/datePicker";
import DropdownComponent from "../../../commonui/commonformcomponents/dropdown/dropdown";
import ReusableLoader from "../../../commonui/reusableloader/reusableLoader";
import MultiCheckbox from "../../../commonui/multiCheckBox/MultiCheckbox";
import Label from "../../../commonui/commonformcomponents/label/Label";
import { customFormatter } from "../../../utility/utility";
import Constant from "../../../utility/constants";
import { getData, postData } from "../../../services/customApis";
import APIEndPoints from "../../../utility/apiendpoints";
import PageNotFound from "../../../commonui/pagenotfound/pageNotFound";
import UnauthorizedPage from "../../../commonui/unauthorizedpage/unauthorized";
import { AiOutlineSearch } from "react-icons/ai";
import { IoInformationCircle } from "react-icons/io5";
import { Button } from "@fluentui/react-northstar";
import ComingSoon from "../../../commonui/comminsoon/comingSoon";
import { UseLibrarySearchFormContext } from "../../../context/librarysearchformcontext/librarySearchFormContext";
import { SearchAndFilterColumnWithLazyLoading } from "../../../commonui/searchandfiltercolumnwithlazyloading/searchAndFilterColumnWithLazyLoading";
import { checkIfDeviceIsMob } from "../../../utility/utility";
import FootnoteSearchForm from "../../../data/librarySearchForm/footNoteForm.json";
import "./librarySearchForm.css";

let resetdropdown = false;
let resetLibrarySearchFormString = "";

function LibrarySearchForm(props) {
  const { librarySearchFormJsonData, handleLibrarySearchFormJsonData } =
    UseLibrarySearchFormContext();
  let internalAppDetails = props.state?.appDetails;
  let templateDetails = props.state?.templateDetails;
  let privilagesForPowerKApp = props.state?.privilagesPowerKApp;
  const navigate = useNavigate();
  let { userInfo } = UseUserInfoContext();
  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [librarySearchFormJson, setLibrarySearchFormJson] = useState({});
  const [resetLibrarySearchFormJson, setResetLibrarySearchFormJson] = useState(
    {}
  );
  const [enableSearch, setEnableSearch] = useState(true);
  const [errorAccessDenied, setAccessDenied] = useState(false);
  const [errorApiCall, setErrorApiCall] = useState(false);
  const [comingSoon, setComingSoon] = useState(false);
  const [searchOptionsOfMobile, setSearchOptionsOfMobile] = useState([]);
  const [specialCharacters, setSpecialCharacters] = React.useState();
  const [disableOrEnableSearchButton, setDisableOrEnableSearchButton] = useState(false);
  const validContentTypeIds = [
    Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK,
    Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES
  ];
  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    const getAllowedChacter = () => {
      getData(APIEndPoints.ALLOWED_SPECIAL_CHARACTERS).then((response) => {
        if (response.data) {
          setSpecialCharacters(response.data);
        }
      });
    };
    getAllowedChacter();
    if (validContentTypeIds.includes(internalAppDetails.contentTypeId)) {
      if (librarySearchFormJsonData.isBackToSearchClicked == true) {
        getAllDefaultOptions(librarySearchFormJsonData.jsonStructure);
        setResetLibrarySearchFormJson(
          librarySearchFormJsonData.resetLibrarySearchForm
        );
        setEnableSearch(false);
        mobileSearchOptions(librarySearchFormJsonData.jsonStructure);
        setIsLoading(false);
      } else {
        getLibrarySearchFormJsonData();
      }
    } else {
      setIsLoading(false);
      setComingSoon(true);
    }
  }, []);
  const mobileSearchOptions = (librarySearchJson) => {
    if (checkIfDeviceIsMob()) {
      const checkboxes = librarySearchJson?.searchOptions?.filter((item) => {
        if (item?.hasOwnProperty("accessibility") && item?.showOption == true) {
          let privileges = item?.accessibility?.privileges;
          if (
            typeof privileges === "object" &&
            privileges?.root?.privilegeCheck == true
          ) {
            let featureFlag =
              privileges?.root?.featureFlagsDetails?.featureFlagKey;
            if (
              Object.keys(privilagesForPowerKApp)
                .map((flag) => flag.toLowerCase())
                .includes(featureFlag.toLowerCase())
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return item?.showOption == true;
        }
      });
      setSearchOptionsOfMobile(checkboxes);
    }
  };
  const resetDefaultJson = () => {
    resetdropdown = true;
    setLibrarySearchFormJson(JSON.parse(resetLibrarySearchFormString));
    props.clearSelectionEnabled("reset");
    setEnableSearch(true);
    console.log(librarySearchFormJsonData.resetLibrarySearchForm);
  };
  useEffect(() => {
    if (props?.cCallFromParent) {
      resetDefaultJson();
    }
  }, [props?.cCallFromParent]);

  useEffect(() => {
    if (
      resetLibrarySearchFormJson != null &&
      resetLibrarySearchFormJson != undefined &&
      Object.keys(resetLibrarySearchFormJson).length > 0
    ) {
      resetLibrarySearchFormString = JSON.stringify(resetLibrarySearchFormJson);
      handleLibrarySearchFormJsonData({
        resetLibrarySearchFormJson: JSON.parse(resetLibrarySearchFormString),
      });
    }
    if (librarySearchFormJsonData.isBackToSearchClicked == true) {
      setLibrarySearchFormJson(librarySearchFormJsonData.jsonStructure);
    }
  }, [resetLibrarySearchFormJson]);

  const isObjectEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
  useEffect(() => {
    if (
      JSON.stringify(librarySearchFormJson) === resetLibrarySearchFormString
    ) {
      props.clearSelectionEnabled("no");
    } else {
      if (!isLoading && !isObjectEmpty(librarySearchFormJson)){
        props.clearSelectionEnabled("yes");
      }
    }
    props?.searchFormOnDBClick(librarySearchFormJson);
  }, [librarySearchFormJson]);

  const getDefaultSection = (librarySearchFormControls) => {
    const defaultOption = librarySearchFormControls?.searchOptions?.find(
      (item) => item?.searchOptionID === "agreement"
    );
    if (!defaultOption) return [];

    const allSections = defaultOption?.section?.sectionControls?.formControls;
    if (!allSections) return [];
    const allDefaultOptions = Object.values(allSections)
      .flatMap((section) => Object.values(section))
      .flat()
      .filter((controlObj) => controlObj?.controlName);
    return { defaultOption, allDefaultOptions };
  };
  const dataSetAfterJsonDataReceive = (librarySearchFormControls) => {
    if (Object.keys(librarySearchFormControls).length > 0) {
      const getDynamicDropdownValues = () => {
        const dynamicObjects = librarySearchFormControls?.searchOptions
          .filter((option) => option.section?.sectionControls?.formControls)
          .flatMap((option) =>
            Object.values(option.section.sectionControls.formControls)
          )
          .flatMap((section) => Object.values(section))
          .flat()
          .filter(
            (controlObj) =>
              controlObj.valueType === "dynamicObject" &&
              !controlObj?.valueObject?.isSearchable
          )
          ?.map((controlObj) => controlObj?.valueObject?.dataFetchKey);
        if (dynamicObjects?.length > 0) {
          getLibrarySearchFieldValues(
            librarySearchFormControls,
            dynamicObjects
          );
        } else {
          setResetLibrarySearchFormJson(librarySearchFormControls);
          setIsLoading(false);
        }
      };

      getDefaultSection(librarySearchFormControls);
      getAllDefaultOptions(librarySearchFormControls);
      getDynamicDropdownValues();
      if (toggleHamburger) {
        handleHamburgerChange();
      }
    }
  };
  const getAllDefaultOptions = (librarySearchFormControls) => {
    const { defaultOption, allDefaultOptions } = getDefaultSection(
      librarySearchFormControls
    );
    if (allDefaultOptions?.length > 0) {
      let filteredData = allDefaultOptions?.filter((controlObj) =>
        defaultOption?.defaultCheckedControls.includes(controlObj?.controlID)
      );
      filteredData?.sort((a, b) => {
        const indexOfA = defaultOption?.defaultCheckedControls?.indexOf(
          a.controlID
        );
        const indexOfB = defaultOption?.defaultCheckedControls?.indexOf(
          b.controlID
        );

        if (indexOfA > indexOfB) {
          return 1;
        } else if (indexOfA < indexOfB) {
          return -1;
        } else {
          return 0;
        }
      });
      setDefaultOptions(filteredData);
    }
  };
  const getLibrarySearchFormJsonData = async () => {
    const params = internalAppDetails
      ? {
          isInternalApp: internalAppDetails.isInternal,
          appId: internalAppDetails.appID,
          customData: {},
          templateId: templateDetails?.templateId?.toString()
        }
      : {
          isInternal: false,
          appId: null,
          customData: {},
          templateId: templateDetails?.templateId?.toString()
        };

    try {
      const jsonTemplateData = await postData(
        params,
        APIEndPoints.GET_SEARCHTEMPLATE_JSON_DETAILS(
          internalAppDetails?.contentTypeId,
          userInfo?.teams?.companyId
        )
      );

      const resultJSONTemplateFileDetails = jsonTemplateData?.data?.resultData;
      handleAttachmentDownload(resultJSONTemplateFileDetails);
    } catch (error) {
      setIsLoading(false);

      if (
        error?.response?.data?.Errors?.[0]?.toLowerCase() === "access denied."
      ) {
        setAccessDenied(true);
      } else {
        setErrorApiCall(true);
      }
    }
  };
  const getLibrarySearchFieldValues = async (
    librarySearchFormControls,
    dataFetchKeyArray
  ) => {
    let resultDropDownValues = {};
    let params = {
      isInternal: false,
      appId: null,
      templateId: templateDetails?.templateId?.toString(),
      dataFetchKey: [],
      customData: {},
    };
    // if internalAppDetails is undefined below code wont run
    if (internalAppDetails) {
      params = {
        isInternalApp: internalAppDetails.isInternal,
        appId: internalAppDetails.appID,
        templateId: templateDetails?.templateId?.toString(),
        dataFetchKey: dataFetchKeyArray,
        customData: {},
      };
    }
    // making api call with params to get JSON Data
    await postData(
      params,
      APIEndPoints.GET_LIBRARY_SEARCH_FIELD_VALUES(
        internalAppDetails?.contentTypeId,
        userInfo?.teams?.companyId
      )
    )
      .then((response) => {
        resultDropDownValues = response?.data?.resultData?.response;
        librarySearchFormControls?.searchOptions.forEach((option) => {
          if (
            option.section &&
            option.section.sectionControls &&
            option.section.sectionControls.formControls
          ) {
            let formControls = option.section.sectionControls.formControls;
            for (let section in formControls) {
              Object.values(formControls[section]).forEach((row) => {
                row?.forEach((control) => {
                  if (control.valueType === "dynamicObject") {
                    let matchingObject = resultDropDownValues.find((obj) => {
                      return (
                        Object.keys(obj)[0] === control.valueObject.dataFetchKey
                      );
                    });
                    if (matchingObject) {
                      control.valueObject = Object.values(matchingObject)[0];
                    }
                  }
                });
              });
            }
          }
        });
        setResetLibrarySearchFormJson(librarySearchFormControls);
        setLibrarySearchFormJson(librarySearchFormControls);
        setIsLoading(false);
      })

      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        if (
          error?.response?.data?.Errors?.[0]?.toLowerCase() == "access denied."
        ) {
          setAccessDenied(true);
        } else {
          setErrorApiCall(true);
        }
      });
  };
  const handleAttachmentDownload = async (fileDetails) => {
    try {
      const response = await postData(
        {
          metaDataId: 0,
          contentType: internalAppDetails.contentTypeId,
          attachments: [fileDetails],
          appId:internalAppDetails.appID
        },
        APIEndPoints.DOWNLOAD_IMAGES(
          internalAppDetails.contentTypeId,
          true,
          userInfo?.teams?.companyId
        )
      );

      if (response?.status === false) {
        throw new Error("Download failed");
      } else if (response?.data !== null) {
        const base64Data = response.data
          .replace("data:application/json;base64,", "")
          .replace("data:application/octet-stream;base64, ", "");
        const decodedString = atob(base64Data);
        try {
          const jsonObject = JSON.parse(decodedString);
          mobileSearchOptions(jsonObject);
          setLibrarySearchFormJson(jsonObject);
          dataSetAfterJsonDataReceive(jsonObject);
          //setIsLoading(false);
        } catch (error) {
          console.error("Failed to parse JSON:", error);
        }
      }
    } catch (error) {
      console.error("Failed to download attachment:", error);
    }
  };
  const getValueBasedOnFieldType = (controlObj, value) => {
    switch (controlObj?.type) {
      case "text":
        return value.target.value;
      case "textarea":
        return value.target.value;
      case "datePicker":
        return customFormatter(new Date(value));
      case "dropdown":
        if (controlObj?.valueType == "dynamicObject") {
          return value?.map((element) => element.name);
        } else if (controlObj?.valueType == "staticObject") {
          return value?.content;
        }
        break;
      default:
        console.log("No control found");
    }
  };
  const controlsChangeHandler = async (
    e,
    controlObj,
    searchOptionID,
    eachSection,
    eachRow
  ) => {
    handleLibrarySearchFormJsonData({
      jsonStructure: {},
      isBackToSearchClicked: false,
      resetLibrarySearchFormJson: {},
    });
    resetdropdown = false;
    let tempLibrarySearchFormJson = [...librarySearchFormJson?.searchOptions];
    let searchOptionIndex = tempLibrarySearchFormJson.findIndex(
      (item) => item.searchOptionID === searchOptionID
    );
    if (searchOptionIndex === -1) {
      return;
    }
    let controls =
      tempLibrarySearchFormJson[searchOptionIndex]?.section?.sectionControls
        ?.formControls?.[eachSection]?.[eachRow];
    if (controls?.length > 0) {
      let controlIndex = controls.findIndex(
        (item) => item?.controlID === controlObj?.controlID
      );
      if (controlIndex === -1) {
        return;
      }
      let value = getValueBasedOnFieldType(controlObj, e);
      if (controls[controlIndex].selectedValue.type == "text") {
        controls[controlIndex].selectedValue.value = value;
        controls[controlIndex].selectedValue.hasUserModified =
          value != null && value != undefined && value != "" ? true : false;
      } else if (value) {
        controls[controlIndex].selectedValue.value = value;
        controls[controlIndex].selectedValue.hasUserModified =
          value != null || value != undefined || value != "" ? true : false;
      }
      setLibrarySearchFormJson((prevState) => {
        const index = prevState.searchOptions.findIndex(
          (item) => item?.searchOptionID === searchOptionID
        );
        if (index !== -1) {
          const item = prevState.searchOptions[index];
          prevState.searchOptions[index] = {
            ...item,
            section: {
              ...item.section,
              sectionControls: {
                ...item.section.sectionControls,
                formControls: {
                  ...item.section.sectionControls.formControls,
                  [eachSection]: {
                    ...item.section.sectionControls.formControls[eachSection],
                    [eachRow]: controls,
                  },
                },
              },
            },
          };
        }
        return { ...prevState };
      });
    }

    // const { defaultOption, allDefaultOptions } = getDefaultSection(
    //   librarySearchFormJson
    // );
    let filteredData = librarySearchFormJson?.searchOptions
      .filter((option) => option.section?.sectionControls?.formControls)
      .flatMap((option) =>
        Object.values(option.section.sectionControls.formControls)
      )
      .flatMap((section) => Object.values(section))
      .flat()
      .filter((controlObj) => controlObj.controlName != "");

    filteredData = filteredData.filter((controlObj) => {
      let value = controlObj.selectedValue.value;
      if (value != null || value != undefined) {
        if (Array.isArray(value) && value.length > 0) {
          return true;
        } else if (
          typeof value === "string" &&
          value.trim() != "" &&
          value.length > 0
        ) {
          return true;
        } else if (
          typeof value === "object" &&
          !Array.isArray(value) &&
          Object.keys(value).length > 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    });
    filteredData.length > 0 ? setEnableSearch(false) : setEnableSearch(true);
  };
  const getValueFromState = (
    controlObj,
    searchOptionID,
    eachSection,
    eachRow
  ) => {
    let tempLibrarySearchFormJson = [...librarySearchFormJson?.searchOptions];
    let searchOptionIndex = tempLibrarySearchFormJson.findIndex(
      (item) => item?.searchOptionID === searchOptionID
    );
    if (searchOptionIndex === -1) {
      return;
    }
    let controls =
      tempLibrarySearchFormJson[searchOptionIndex]?.section?.sectionControls
        ?.formControls?.[eachSection]?.[eachRow];
    if (controls?.length > 0) {
      let controlIndex = controls.findIndex(
        (item) => item?.controlID === controlObj?.controlID
      );
      if (controlIndex === -1) {
        return;
      }
      return controlObj, controls[controlIndex]?.selectedValue?.value;
    }
  };
  const assignDropdownValue = (controlObj) => {
    switch (controlObj?.valueType) {
      case "staticObject":
        return controlObj?.valueObject?.data;
      case "dynamicObject":
        return [];
      default:
        return null;
    }
  };
  const assignDefaultValue = (
    controlObj,
    searchOptionID,
    eachSection,
    eachRow
  ) => {
    switch (controlObj?.valueType) {
      case "staticObject":
        if (librarySearchFormJsonData.isBackToSearchClicked == true) {
          if (controlObj?.selectedValue?.value) {
            return controlObj?.selectedValue?.value;
          } else {
            return controlObj?.valueObject?.defaultKey;
          }
        } else {
          return controlObj?.valueObject?.defaultKey;
        }
      case "dynamicObject":
        return getObjectBasedOnValue(
          controlObj,
          searchOptionID,
          eachSection,
          eachRow
        );
      default:
        return null;
    }
  };
  const getObjectBasedOnValue = (
    controlObj,
    searchOptionID,
    eachSection,
    eachRow,
    isFromFormatting,
    jsonStructure
  ) => {
    let selectedValue = controlObj?.selectedValue?.value;
    let filteredList = [];
    if (librarySearchFormJsonData.isBackToSearchClicked == true) {
      if (selectedValue?.length > 0) {
        selectedValue?.map((eachValue) => {
          filteredList.push(
            controlObj?.valueObject?.data?.filter((item) => {
              return item?.value === eachValue;
            })?.[0]
          );
        });
      }
      if (isFromFormatting) {
        let tempLibrarySearchFormJson = [...jsonStructure.searchOptions];
        let controls =
          tempLibrarySearchFormJson[searchOptionID]?.section?.sectionControls
            ?.formControls?.[eachSection]?.[eachRow];
        if (controls?.length > 0) {
          let controlIndex = controls.findIndex(
            (item) => item?.controlID === controlObj?.controlID
          );
          if (controlIndex === -1) {
            return;
          }
          controls[controlIndex].selectedValue.value = filteredList;

          setLibrarySearchFormJson((prevState) => {
            const index = prevState.searchOptions.findIndex(
              (item) => item?.searchOptionID === searchOptionID
            );
            if (index !== -1) {
              const item = prevState.searchOptions[index];
              prevState.searchOptions[index] = {
                ...item,
                section: {
                  ...item.section,
                  sectionControls: {
                    ...item.section.sectionControls,
                    formControls: {
                      ...item.section.sectionControls.formControls,
                      [eachSection]: {
                        ...item.section.sectionControls.formControls[
                          eachSection
                        ],
                        [eachRow]: controls,
                      },
                    },
                  },
                },
              };
            }
            return { ...prevState };
          });
        }
      }
      return filteredList;
    } else {
      return selectedValue?.length > 0 ? selectedValue : "";
    }
  };
  const columnFilteringMethodSearchAndFilter = (
    controlObj,
    searchOptionID,
    eachSection,
    eachRow,
    selectedValues,
    isClearAll
  ) => {
    if((selectedValues.length !== 0) || isClearAll){
    controlsChangeHandler(
      selectedValues,
      controlObj,
      searchOptionID,
      eachSection,
      eachRow
    );
    }
  };
  const onChangeCheckedValue = (e, checkboxProps) => {
    if (checkboxProps?.checked == false) {
      let updatedOptionData = [...librarySearchFormJson.searchOptions];
      let filteredData = updatedOptionData?.find((controls) => {
        return controls.searchOptionID == checkboxProps.name;
      });
      const allSections = filteredData?.section?.sectionControls?.formControls;
      if (!allSections) return [];
      const allDefaultOptions = Object.values(allSections)
        .flatMap((section) => Object.values(section))
        .flat()
        .filter((controlObj) => controlObj?.controlName);
      allDefaultOptions.map((controlObj) => {
        if (controlObj?.controlName) {
          controlObj.selectedValue.value =
            controlObj?.selectedValue?.type == "text" ? "" : [];
          controlObj.selectedValue.hasUserModified = false;
        } else {
          controlObj.selectedValue.value = controlObj.defaultValue;
          controlObj.selectedValue.hasUserModified = false;
        }
        return controlObj;
      });
      setLibrarySearchFormJson((prevState) => {
        const index = prevState.searchOptions.findIndex(
          (item) => item?.searchOptionID === checkboxProps.name
        );
        if (index !== -1) {
          const item = prevState.searchOptions[index];
          prevState.searchOptions[index] = {
            ...item,
            isChecked: false,
            section: filteredData?.section,
          };
        }
        return { ...prevState };
      });
      setDisableOrEnableSearchButton(true);
    } else {
      setLibrarySearchFormJson((prevState) => {
        return {
          ...prevState,
          searchOptions: prevState.searchOptions.map((item) => {
            if (item.searchOptionID === checkboxProps.name) {
              return {
                ...item,
                isChecked: checkboxProps?.checked,
              };
            }
            return item;
          }),
        };
      });
    }
  }
  useEffect(() => {
    if(disableOrEnableSearchButton == true){
      let filteredData = librarySearchFormJson?.searchOptions
      .filter((option) => option.section?.sectionControls?.formControls)
      .flatMap((option) =>
        Object.values(option.section.sectionControls.formControls)
      )
      .flatMap((section) => Object.values(section))
      .flat()
      .filter((controlObj) => controlObj.controlName != "");

    filteredData = filteredData.filter((controlObj) => {
      let value = controlObj.selectedValue.value;
      if (value != null || value != undefined) {
        if (Array.isArray(value) && value.length > 0) {
          return true;
        } else if (
          typeof value === "string" &&
          value.trim() != "" &&
          value.length > 0
        ) {
          return true;
        } else if (
          typeof value === "object" &&
          !Array.isArray(value) &&
          Object.keys(value).length > 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    });
    filteredData.length > 0 ? setEnableSearch(false) : setEnableSearch(true);
    setDisableOrEnableSearchButton(false);
    }
  }, [disableOrEnableSearchButton, librarySearchFormJson]);

  const renderControl = (controlObj, searchOptionID, eachSection, eachRow) => {
    if (controlObj != undefined) {
      switch (controlObj.type) {
        case "text":
          return (
            <>
              <TextBox
                placeholder={controlObj.placeholder}
                required={controlObj.isRequired}
                onChange={(e) => {
                  controlsChangeHandler(
                    e,
                    controlObj,
                    searchOptionID,
                    eachSection,
                    eachRow
                  );
                }}
                additionalMessage={controlObj.additionalMessage}
                value={getValueFromState(
                  controlObj,
                  searchOptionID,
                  eachSection,
                  eachRow
                )}
                fieldsName={controlObj?.fieldsName}
                isCustomField={controlObj?.isCustomData}
                validations={controlObj?.validations}
                isContentField={controlObj?.isContentData}
                parentName={controlObj?.parentName}
                name={controlObj?.controlLabelName}
                id={controlObj?.controlLabelName}
                disabled={false}
                isLibrarySearch={
                  librarySearchFormJsonData.isBackToSearchClicked ? true : false
                }
              />
            </>
          );
        // case "textarea":
        //      return (
        //           <TextArea
        //                placeholder={controlObj.Placeholder}
        //                required={controlObj.IsRequired}
        //                height={"90px"}
        //                onChange={controlsChangeHandler}
        //                additionalMessage={controlObj.AdditionalMessage}
        //                value={""}
        //                fieldsName={controlObj?.FieldsName}
        //                validations={controlObj?.Validations}
        //                name={controlObj?.Name}
        //                id={controlObj?.Name}
        //                disabled={false}
        //           />
        //      );
        case "datePicker":
          let val = getValueFromState(
            controlObj,
            searchOptionID,
            eachSection,
            eachRow
          );
          if (val != null && val != undefined && val != "") {
            val = new Date(val);
          }
          return (
            <DatePicker
              onChangeHandler={(e) => {
                controlsChangeHandler(
                  e,
                  controlObj,
                  searchOptionID,
                  eachSection,
                  eachRow
                );
              }}
              allowManualInput={true}
              inputPlaceholder="mm-dd-yyyy"
              clearable={true}
              name={controlObj?.Name}
              placeholder={controlObj.placeholder}
              value={val}
              id={controlObj?.controlLabelName}
              disabled={false}
              isLibrarySearch={
                librarySearchFormJsonData.isBackToSearchClicked ? true : false
              }
            />
          );
        case "dropdown":
          if (controlObj?.valueType == "dynamicObject") {
            if (controlObj?.valueObject?.isSearchable) {
              return (
                <SearchAndFilterColumnWithLazyLoading
                  appDetails={internalAppDetails}
                  appSearchQueryValue={""}
                  fieldName={controlObj?.controlName}
                  columnFilteringUpdateMethod={(
                    controlObj,
                    searchOptionID,
                    eachSection,
                    eachRow,
                    selectedValues,
                    isClearAll
                  ) => {
                    columnFilteringMethodSearchAndFilter(
                      controlObj,
                      searchOptionID,
                      eachSection,
                      eachRow,
                      selectedValues,
                      isClearAll
                    );
                  }}
                  isFromSearch={true}
                  controlParams={{
                    controlObj: controlObj,
                    searchOptionID: searchOptionID,
                    eachSection: eachSection,
                    eachRow: eachRow,
                  }}
                  isLibrarySearch={
                    librarySearchFormJsonData.isBackToSearchClicked
                      ? true
                      : false
                  }
                  templateDetails={templateDetails}
                  specialCharacters={specialCharacters}
                />
              );
            } else {
              return (
                <MultiSelectReactDropdown
                  options={controlObj?.valueObject?.data}
                  showCheckbox={true}
                  selectedValues={
                    resetdropdown == true
                      ? []
                      : getValueFromState(
                          controlObj,
                          searchOptionID,
                          eachSection,
                          eachRow
                        )
                  }
                  emptyRecordMsg={controlObj.EmptyMsg}
                  placeholder={controlObj.placeHolderText}
                  displayValue={"value"}
                  customCloseIcon={true}
                  onSelect={(selectedList) => {
                    controlsChangeHandler(
                      selectedList,
                      controlObj,
                      searchOptionID,
                      eachSection,
                      eachRow
                    );
                  }}
                  onRemove={(selectedList) => {
                    controlsChangeHandler(
                      selectedList,
                      controlObj,
                      searchOptionID,
                      eachSection,
                      eachRow
                    );
                  }}
                  name={controlObj?.controlID}
                  closeOnSelect={true}
                  showArrow={true}
                  hideSelectedList={false}
                  isDragDisabled={true}
                  defaultCheckedValue={
                    resetdropdown == true
                      ? ""
                      : assignDefaultValue(
                          controlObj,
                          searchOptionID,
                          eachSection,
                          eachRow
                        )
                  }
                  id={controlObj?.controlID}
                  clearData={resetdropdown}
                  isLibrarySearch={
                    librarySearchFormJsonData.isBackToSearchClicked
                      ? true
                      : false
                  }
                />
              );
            }
          } else if (controlObj?.valueType == "staticObject") {
            return (
              <DropdownComponent
                items={assignDropdownValue(controlObj)}
                fieldsName={controlObj?.fieldsName}
                isCustomField={controlObj?.isCustomData}
                onDropDownChange={(e) => {
                  controlsChangeHandler(
                    e,
                    controlObj,
                    searchOptionID,
                    eachSection,
                    eachRow
                  );
                }}
                defaultCheckedValue={assignDefaultValue(controlObj)}
                validations={controlObj?.validations}
                parentName={controlObj?.ParentName}
                name={controlObj?.name}
                placeholder={controlObj?.placeHolderText}
                value={getValueFromState(
                  controlObj,
                  searchOptionID,
                  eachSection,
                  eachRow
                )}
                search={controlObj?.isSearchable}
                checkable={controlObj?.isCheckable}
                id={controlObj?.name}
                disabled={false}
                multiple={controlObj?.isMultiSelect}
                page={"LibrarySearchForm"}
                initialLoad={true}
                isLibrarySearch={
                  librarySearchFormJsonData.isBackToSearchClicked ? true : false
                }
              />
            );
          }
          break;
        default:
          console.log("No control found");
      }
    }
  };
  const navigateToLibraryGrid = () => {
    let librarySearchFormJson_JSON;
    let formJsonDetails_string = JSON.stringify(librarySearchFormJson);
    let formJsonDetails_DateUpdated = JSON.parse(formJsonDetails_string);
    let updatedOptionData = formJsonDetails_DateUpdated?.searchOptions;
      let filteredData = updatedOptionData?.find((controls) => {
        return controls.searchOptionID.toLowerCase() === "date";
      });
      const allSections = filteredData?.section?.sectionControls?.formControls;
      // if (!allSections) return [];
      const allDefaultOptions = allSections 
      ? Object.values(allSections)
          .flatMap((section) => section ? Object.values(section) : [])
          .flat()
          .filter((controlObj) => controlObj?.controlName)
      : [];
      const filteredSelectedDate = allDefaultOptions?.filter((item)=>item?.selectedValue?.value !== "01-01-1970" && item?.selectedValue?.value !== "")
      if(filteredSelectedDate?.length === 1)
      {
      allDefaultOptions.map((controlObj) => {
        if (controlObj?.selectedValue?.value !== filteredSelectedDate[0]?.selectedValue?.value) {
          controlObj.selectedValue.value = filteredSelectedDate[0]?.selectedValue?.value;
        } 
        
        return controlObj;
      });
      librarySearchFormJson_JSON = formJsonDetails_DateUpdated;
     
    }
    else{
      librarySearchFormJson_JSON = librarySearchFormJson
    }
    handleLibrarySearchFormJsonData({
      jsonStructure: librarySearchFormJson_JSON,
      isBackToSearchClicked: true,
      columnFilteringApplied: false,
      appliedFilteredValues:{},
      searchQuery:"",
      totalRecordsCount:0    
    });
    navigate("/apps/LibraryGrid", {
      state: {
        appDetails: internalAppDetails,
        formJson: librarySearchFormJson_JSON,
        privilagesPowerKApp: privilagesForPowerKApp,
        templateDetails: templateDetails
      },
    });
  };
  const checkControlPrivileges = (
    eachParam,
    controlObj,
    privilagesForPowerKApp
  ) => {
    if (
      eachParam?.hasOwnProperty("accessibility") &&
      eachParam?.showOption == true
    ) {
      let controlLevelPrivileges =
        eachParam?.accessibility?.privileges?.controlLevel;
      if (controlLevelPrivileges?.length > 0) {
        let isPresent = controlLevelPrivileges?.filter((item) => {
          return item?.controlId == controlObj?.controlID;
        });
        if (isPresent?.length > 0) {
          let featureFlag = isPresent[0].featureFlagsDetails?.featureFlagKey;
          if (
            Object.keys(privilagesForPowerKApp)
              .map((flag) => flag.toLowerCase())
              .includes(featureFlag.toLowerCase())
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  if (errorApiCall) return <PageNotFound />;
  if (errorAccessDenied) return <UnauthorizedPage />;
  if (comingSoon) return <ComingSoon />;

  const renderMobileView = (formControls, item, sectionKey) => {
    const { leftSection, rightSection } = formControls;
    if (!leftSection && !rightSection) return null;
  
    const keys = [
      ...new Set([
        ...Object.keys(leftSection ?? {}),
        ...Object.keys(rightSection ?? {}),
      ]),
    ];
    const sortedKeys = keys?.length ? keys.sort() : keys;

    return sortedKeys?.length > 0 ? sortedKeys.map((key) => {
      const leftSectionControls = leftSection?.[key] || [];
      const rightSectionControls = rightSection?.[key] || [];
    
      const updateControls = (controls, section) =>
        controls.map((control) => ({ ...control, section }));
    
      const allControls = [
        ...updateControls(leftSectionControls, "leftSection"),
        ...updateControls(rightSectionControls, "rightSection"),
      ];
    
      return allControls.length > 0
        ? allControls.map((controlObj) =>
            renderMobileControls(
              controlObj,
              item.searchOptionID,
              controlObj.section,
              key,
              sectionKey
            )
          )
        : null;
    }) : null;
  };
  const renderMobileControls = (
    controlObj,
    searchOptionId,
    eachSection,
    eachRow,
    key
  ) => {
    return (
      <div
        className={`mobileRow ${
          controlObj.valueType?.toLowerCase() == "staticobject" &&
          controlObj.valueObject?.data?.length < 4
            ? "staticDropdowns"
            : ""
        }`}
      >
        {controlObj?.controlLabelName != undefined && (
          controlObj?.acronym?.length > 0 ? (
            <div className="infoIconDisplay">
              <Label
                content={
                  controlObj?.controlLabelName
                }
              />
              <div>
                <IoInformationCircle title={controlObj?.acronym} />
              </div>
            </div>
          ) : (
            <Label
              content={
                controlObj?.controlLabelName
              }
            />
          )
        )}
        {renderControl(controlObj, searchOptionId, eachSection, eachRow)}
      </div>
    );
  };

  return (
    <>
      {isLoading && <ReusableLoader isOverlay={true} isAppBody={true} />}
      <div className="container">
          <MultiCheckbox
            checkBoxArray={librarySearchFormJson?.searchOptions}
            onChange={onChangeCheckedValue}
            className={"MultiCheckbox"}
            defaultOptions={defaultOptions}
            featureFlags={privilagesForPowerKApp}
          />
      </div>
      {librarySearchFormJson?.searchOptions?.length > 0 &&
        librarySearchFormJson?.searchOptions?.map((item, searchKey) => {
          return (
            item?.isChecked === true &&
            Object.keys(item?.section?.sectionControls)?.length > 0 &&
            Object.keys(item?.section?.sectionControls).map((formControl) => {
              let formControls = item?.section?.sectionControls[formControl];
              return (
                <div
                  className={`container containerBorder containerMobileScreen ${
                    checkIfDeviceIsMob() ? "mobileContainer" : ""
                  }`}
                  key={searchKey}
                >
                  {checkIfDeviceIsMob()
                    ? renderMobileView(formControls, item, searchKey)
                    : Object.keys(formControls)?.length > 0 &&
                      Object.keys(formControls).map((section, sectionKey) => {
                        let eachSection = formControls?.[section];
                        return (
                          <div className="column" key={sectionKey}>
                            {Object.keys(eachSection)?.length > 0 &&
                              Object.keys(eachSection).map((row, rowIndex) => {
                                let eachRow = eachSection?.[row];
                                return (
                                  <div
                                    className={`row ${
                                      item.section?.hasNoRightSection
                                        ? "dateControls"
                                        : ""
                                    }`}
                                    key={rowIndex}
                                  >
                                    {eachRow?.length > 0 &&
                                      eachRow?.map((controlObj, key) => {
                                        if (
                                          checkControlPrivileges(
                                            item,
                                            controlObj,
                                            privilagesForPowerKApp
                                          )
                                        ) {
                                          return (
                                            <div
                                              className="control librarySearchForm"
                                              key={key}
                                            >
                                              {controlObj?.controlLabelName !=
                                                undefined && (
                                                  controlObj?.acronym?.length > 0 ? (
                                                    <div className="infoIconDisplay">
                                                      <Label
                                                        content={
                                                          controlObj?.controlLabelName
                                                        }
                                                      />
                                                      <div>
                                                        <IoInformationCircle title={controlObj?.acronym} />
                                                      </div>
                                                    </div>
                                                  ) : (
                                                <Label
                                                  content={
                                                    controlObj?.controlLabelName
                                                  }
                                                />
                                                )
                                              )}
                                              {renderControl(
                                                controlObj,
                                                item.searchOptionID,
                                                Object.keys(formControls)?.[
                                                  sectionKey
                                                ],
                                                Object.keys(eachSection)?.[
                                                  rowIndex
                                                ]
                                              )}
                                            </div>
                                          );
                                        }
                                      })}
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                </div>
              );
            })
          );
        })}
      <div className="formFooter">
        <div className="FooterSplitButtons">
          <Button
            className="SearchButton"
            size="small"
            onClick={() => navigateToLibraryGrid()}
            icon={<AiOutlineSearch />}
            content={Constant.INTERNALAPP_CONSTANTS.SEARCH_BUTTON}
            disabled={enableSearch}
          />
        </div>
      </div>
    </>
  );
}
export default LibrarySearchForm;
