import _ from "lodash";
import React, { useState, createContext, useContext, useEffect } from "react";
import { UseUserInfoContext } from "../usercontext/userContext";

export const userManagementObj = {
  pagination: {
    pageLength: 10,
    pageNumber: 1
  },
  searchText: "",
  usersList: [],
  filterUsersList: [],
  addUsersList: [],
  roles: [],
  fetchingUsersList: false,
  fetchingSearchResult: false,
  fetchingRoles: false,
  showDeleteUserLoader: false,
  showChangeRoleLoader: false,
  leaveCompanyDialogOpen: false,
  addMemberDialogOpen: false,
  addExternalMemberDialogOpen: false,
  owner: false,
  featureFlags: {},
  fetchedFeatureFlags: false,
  resetCompanies: false,

};
const userManagementContextData = createContext({
  usersData: userManagementObj,
  handleDataChange: (props) => { }
});

export function UseUserManagementContext() {
  return useContext(userManagementContextData);
}

const UserManagementContext = ({ children }) => {

  const [usersData, setUsersData] = useState(userManagementObj);
  const { userInfo } = UseUserInfoContext();


  const handleDataChange = (props) => {
    setUsersData((prevData) => {
      return { ...prevData, ...props };
    });
  };



  const searchUsers = (searchString) => {
    if (!searchString) return [];

    const lowerSearch = searchString.toLowerCase();

    return usersData?.usersList?.filter((user) => {
      return user?.slug.includes(lowerSearch);
    });
  };



  useEffect(() => {
    if (usersData.searchText !== "") {
      handleDataChange({ filterUsersList: searchUsers(usersData.searchText) });
    }
  }, [usersData.searchText]);



  return (
    <userManagementContextData.Provider value={{ usersData, handleDataChange }}>
      {children}
    </userManagementContextData.Provider>
  );
};

export default UserManagementContext;
