import React, { useEffect, useState } from "react";
import LibrarySearchForm from "./librarySearchForm/librarySearchForm.jsx";
import {
  useMenuContext,
  useHamburgerMenuContext,
} from "../../context/menucontext/menuContext";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { Link, useLocation } from "react-router-dom";
import sideNavMainMenuData from "../../data/menu/sideNavMainMenuData";
import { TbDatabaseSearch, TbXboxX } from "react-icons/tb";
import { getData, postData } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import PageNotFound from "../../commonui/pagenotfound/pageNotFound";
import UnauthorizedPage from "../../commonui/unauthorizedpage/unauthorized";
import Constant from "../../utility/constants";
import { getPrivilegeValue } from "../../utility/utility";
import { UseLibrarySearchFormContext } from "../../context/librarysearchformcontext/librarySearchFormContext";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import { BackButton } from "../../commonui/backbutton/backButton.jsx";
import LandingPage from "./landingPage/landingPage.jsx";
import Alert from "../../commonui/commonformcomponents/alert/alert";
import ContactUs from "../../components/contactus/contactUs";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import { MdClose } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
} from "@fluentui/react";
import { Button } from "@fluentui/react-northstar";
import { Image } from "@fluentui/react-northstar";
import { UseContactUsContext } from "../../context/contactuscontext/contactUsContext";
import "./internalAppDetail.css";
export default function InternalAppTabsDetails() {
  const { librarySearchFormJsonData, handleLibrarySearchFormJsonData } =
    UseLibrarySearchFormContext();
  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
  const { menuState, handleChangeNav } = useMenuContext();
  const { handleMenuInfo } = UseContactUsContext();
  const { userInfo } = UseUserInfoContext();
  const [loading, setLoading] = useState(true);
  const [errorAccessDenied, setAccessDenied] = useState(false);
  const [errorApiCall, setErrorApiCall] = useState(false);
  const [privilagesForPowerKApp, setPrivilagesForPowerKApp] = useState({});
  const [errorAPI, setErrorAPI] = useState(false);
  const [clearSelectionStateVisible, setClearSelectionStateVisible] =
    useState(false);
  const [callFromParent, setCallFromParent] = useState(false);
  const [parentToChild, setParentToChild] = useState(false);
  const [jsonStructure, setJsonStructure] = useState({});
  const [showToaster, setShowToaster] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tourcards, setTourCards] = useState([]);
  const location = useLocation();
  const customeSlider = React.createRef();
  let internalAppDetails: any = (location.state as any)?.appDetails;
  // Need to add template details for each content type ID
  const contentTypeTemplateMap = {
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]: "10",
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK]: "1",
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES]: "1",
    // Add other contentTypeIDs and their corresponding templateIds here
  };

  const getTemplateId = (contentTypeId) => {
    return contentTypeTemplateMap[contentTypeId];
  };

  const [templateDetailsObj, setTemplateDetailsObj] = useState({
    templateId: getTemplateId(internalAppDetails?.contentTypeId),
  });
  const setBreadCrumbValue = () => {
    menuState?.menu?.name == "Apps" &&
      handleChangeNav({
        section: {
          id: sideNavMainMenuData[3]?.id,
          name: "",
        },
        menu: {
          id: sideNavMainMenuData[3]?.menu[0]?.id,
          name: "Apps",
        },
        submenu: {
          id: sideNavMainMenuData[3]?.menu[0]?.submenu[0]?.id,
          name: internalAppDetails?.breadCrumb,
        },
        data: "",
        to: "/apps/LibrarySearchPage",
      });
  };

  // this method to check privilage access for powerk App
  const checkPrivilagesForPowerKApp = async () => {
    if (!userInfo?.teams?.key) return;
    const contentTypeId = internalAppDetails?.contentTypeId;
    try {
      const response = await getData(
        APIEndPoints.GET_PAGE_LEVEL_FLAGS(
          userInfo?.teams?.companyId,
          null,
          contentTypeId
        )
      );
      const targetItem = response.data.contentType.find(
        (item) => item.contentTypeId === contentTypeId
      );
      if (targetItem) {
        internalAppDetails?.isGlobal
          ? setPrivilagesForPowerKApp((prevData) => ({
              ...prevData,
              ...targetItem?.subContentTypes[0]?.contentCategories[0]
                ?.featureFlagsDetails,
            }))
          : setPrivilagesForPowerKApp((prevData) => ({
              ...prevData,
              ...targetItem?.subContentTypes[0]?.contentCategories[1]
                ?.featureFlagsDetails,
            }));
      }
    } catch (error: any) {
      if (
        error?.response?.data?.Errors[0]?.toLowerCase() === "access denied."
      ) {
        setAccessDenied(true);
      } else {
        setErrorApiCall(true);
      }
    }
  };
  const clearSelectionEnabled = (changeState: any) => {
    if (changeState === "yes") setClearSelectionStateVisible(true);
    else if (changeState === "no") setClearSelectionStateVisible(false);
    else if (changeState === "reset") {
      setCallFromParent(false);
      setClearSelectionStateVisible(false);
    }
  };
  const clearSelectionPowerKApp = () => {
    if (librarySearchFormJsonData?.powerKLibrarySearchGridData?.length>0){
      setParentToChild(true);
    }
    else{
      setParentToChild(false);
    }
  };
  useEffect(() => {
    checkPrivilagesForPowerKApp().then(() => {
      setLoading(false);
    });
    if (librarySearchFormJsonData.specialCharacters.length < 2) {
      getData(APIEndPoints.ALLOWED_SPECIAL_CHARACTERS).then((response) => {
        if (response?.data) {
          handleLibrarySearchFormJsonData({
            specialCharacters: response?.data,
          });
        }
      });
    }
    window?.scroll(0, 0);
    if (toggleHamburger && window.innerWidth > 900) {
      handleHamburgerChange();
    }
    setBreadCrumbValue();
  }, []);
  useEffect(() => {
    if(!toggleHamburger)
    {
      let sidebarWeb =  document.getElementsByClassName("sidebar-web");
    if (sidebarWeb?.length > 0) {
      Array.from(sidebarWeb)?.forEach((image) => {
        image.classList.add("dynamicHeight");
      });
    }
    let dynamicGridColumns =  document.getElementsByClassName("teamAppContainer_sm");
    if (dynamicGridColumns?.length > 0) {
      Array.from(dynamicGridColumns)?.forEach((image) => {
        image.classList.add("dynamicGridColumns");
      });
    }
    }
  }, [toggleHamburger]);

  const searchFormOnDBClick = (json) => {
    setJsonStructure(json);
  };
  const handleOnClickDBGrid = () => {
    handleLibrarySearchFormJsonData({
      jsonStructure: jsonStructure,
      isBackToSearchClicked: true,
    });
  };
  const handleOnClickContactUs = () => {
    handleMenuInfo({
      menuName: "Apps",
      subMenuName: Constant.INTERNALAPP_CONSTANTS.POWERK_TITLE,
    });
  };
  useEffect(() => {
    const getTakeTourDetails = async () => {
      await postData({}, APIEndPoints.GET_TAKETOUR_DETAILS(2), false)
        .then((response) => {
          if (response?.status === 200) {
            setTourCards(response?.data);
          }
        })
        .catch((error) => console.log("error", error));
    };
    getTakeTourDetails ();
  }, []);
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
    lazyLoad: true,
  };
  const takeaTourDialog = () => {
    return (
      <div>
        <Slider ref={customeSlider} {...sliderSettings}>
          {tourcards?.length > 0 &&
            tourcards.map((card, index) => (
              <div key={index}>
                <div className="tat-div-image">
                  <ImageComponent card={card} />
                </div>
                <h5 className="tat-header-title">{card["pageHeader"]}</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: card["pageContent"],
                  }}
                />
              </div>
            ))}
        </Slider>
      </div>
    );
  };
  if (errorApiCall) return <PageNotFound />;
  if (errorAccessDenied) return <UnauthorizedPage />;

  return loading ? (
    <ReusableLoader isOverlay={true} isAppBody={true} />
  ) : (
    !loading && (
      <>
        <div className="internalApp_container">
          {internalAppDetails &&
            privilagesForPowerKApp &&
            internalAppDetails?.contentTypeId ==
              Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK && internalAppDetails?.version == Constant.SEARCH_TEMPLATE_VERSIONS.NEW_TEMPLATE && (
              <Alert
                content={
                  <div className="featuresAlertContainer">
                    <div className="featuresAlertLeft">
                      <Button
                        className="searchFilterPills"
                        key="1"
                        content="Upcoming new features"
                        onClick={() => {
                          return setIsModalOpen(true);
                        }}
                      />
                      <p className="featuresAlertText">
                        If you want to leave us some suggestions, please click
                        on Contact us
                      </p>
                    </div>
                    <div className="featuresAlertRight">
                    <Link
                        to="/contactUs"
                        className="featuresAlertLink"
                        onClick={handleOnClickContactUs}
                        state={{ appDetails: internalAppDetails, privilagesPowerKApp: privilagesForPowerKApp, templateDetails: templateDetailsObj }}
                      >
                        Contact Us
                      </Link>
                      <Link
                        to="/apps/DatabaseGrid"
                        state={{
                          appDetails: internalAppDetails,
                          privilagesPowerKApp: privilagesForPowerKApp,
                          templateDetails: templateDetailsObj,
                        }}
                        className="databaseLinkStyleDetails_internalApp"
                        onClick={handleOnClickDBGrid}
                      ></Link>
                    </div>
                  </div>
                }
                visible={showToaster}
                warning={true}
                dismissible={true}
                onClickDismiss={() => {
                  setShowToaster(false);
                }}
                className={"featuresAlert"}
              />
            )}
          <div id={"LibrarySearch"}>
            <div className="container_internalApps common_container_subtitle_internalApp">
              <div className="common_container_rightMenu_internalApp">
                <div className="divTxtSubmenu_internalApp">
                  <header style={{ maxWidth: "100%" }}>
                    <p style={{ display: "flex" }}>
                      {
                        <>
                          <span className="commonTitleEllipses_internalApp showEllipses_internalApp">
                            {internalAppDetails?.contentTypeId ==
                    Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES? 
                    Constant.INTERNALAPP_CONSTANTS.SEARCH_BUTTON : Constant.INTERNALAPP_CONSTANTS.LIBRARY_SEARCH}
                          </span>
                        </>
                      }
                    </p>
                  </header>
                </div>
                <div className="dataBaseLink_internalApp">
                  {internalAppDetails &&
                    getPrivilegeValue(
                      privilagesForPowerKApp,
                      "ViewDatabase",
                      "display"
                    ) &&
                    internalAppDetails?.contentTypeId !=
                      Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES && (
                      <Link
                        to="/apps/DatabaseGrid"
                        state={{
                          appDetails: internalAppDetails,
                          privilagesPowerKApp: privilagesForPowerKApp,
                          templateDetails: templateDetailsObj,
                        }}
                        className="databaseLinkStyleDetails_internalApp"
                        onClick={handleOnClickDBGrid}
                      >
                        <TbDatabaseSearch />
                        {Constant.INTERNALAPP_CONSTANTS.DATABASE_LINK}
                      </Link>
                    )}
        
                  {internalAppDetails?.contentTypeId ==
                    Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK && (
                    <div
                      className={
                        "clearSelectionLink_internalApp " +
                        (librarySearchFormJsonData?.powerKLibrarySearchGridData?.length>0
                          ? "selection-enable"
                          : "selection-disable")
                      }
                      onClick={() => {
                        clearSelectionPowerKApp();
                      }}
                    >
                      <TbXboxX />
                      {Constant.INTERNALAPP_CONSTANTS.CLEAR_SELECTION}
                    </div>
                  )
                  }
                  <BackButton
                    allowRedirection={true}
                    pathToBeRedirected={"/apps"}
                    isCloseClicked={true}
                    className="PowerK-close-btn"
                  />
                </div>
              </div>
            </div>
            {(internalAppDetails &&
            privilagesForPowerKApp &&
            ((privilagesForPowerKApp as any)?.librarySearch
            ?.librarySearchDisplay !== false && (privilagesForPowerKApp as any)?.librarySearch
            ?.librarySearchEnabled !== false &&
            ((internalAppDetails?.contentTypeId == Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK) ||
            (internalAppDetails?.contentTypeId ==  Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES)) && internalAppDetails?.version == Constant.SEARCH_TEMPLATE_VERSIONS.NEW_TEMPLATE ? (
              <LandingPage
                state={{
                  appDetails: internalAppDetails,
                  privilagesPowerKApp: privilagesForPowerKApp,
                  templateDetails: templateDetailsObj,
                }}
                parentToChild={parentToChild}
                setParentToChild = {setParentToChild}
              />
            ) : (
              <UnauthorizedPage />
            ))
            ) || (
              internalAppDetails &&
              privilagesForPowerKApp &&
              ((privilagesForPowerKApp as any)?.LibrarySearch
                ?.LibrarySearchDisplay !== false && internalAppDetails?.version == Constant.SEARCH_TEMPLATE_VERSIONS.OLD_TEMPLATE ? (
                <LibrarySearchForm
                  state={{
                    appDetails: internalAppDetails,
                    privilagesPowerKApp: privilagesForPowerKApp,
                    templateDetails: templateDetailsObj,
                  }}
                  clearSelectionEnabled={(changeState: any) => {
                    clearSelectionEnabled(changeState);
                  }}
                  cCallFromParent={callFromParent}
                  searchFormOnDBClick={searchFormOnDBClick}
                />
              ) : (
                <UnauthorizedPage />
              ))
            )}
          </div>
        </div>
        {
          <DialogBox
            closeOnOutsideClick={false}
            content={takeaTourDialog()}
            headerActionName={{
              icon: <MdClose />,
              title: "Close",
              onClick: () => {
                setIsModalOpen(false);
              },
            }}
            className="newUpcomingFeaturesDialoag"
            backdrop={true}
            openDialog={isModalOpen}
          />
        }
      </>
    )
  );
}
const wrapperStyle = { display: "flex" };

const getCustomElementsExampleThree = () => {
  return (
    <div style={wrapperStyle}>
      <ShimmerElementsGroup
        width={"90px"}
        shimmerElements={[
          { type: ShimmerElementType.line, height: 80, width: 80 },
          { type: ShimmerElementType.gap, width: 10, height: 80 },
        ]}
      />
      <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        <ShimmerElementsGroup
          shimmerElements={[
            { type: ShimmerElementType.circle, height: 40 },
            { type: ShimmerElementType.gap, width: 10, height: 40 },
          ]}
        />
        <ShimmerElementsGroup
          flexWrap
          width={"calc(100% - 50px)"}
          shimmerElements={[
            { type: ShimmerElementType.line, width: "90%", height: 10 },
            { type: ShimmerElementType.gap, width: "10%", height: 20 },
            { type: ShimmerElementType.line, width: "100%", height: 10 },
          ]}
        />
        <ShimmerElementsGroup
          flexWrap
          width={"100%"}
          shimmerElements={[
            {
              type: ShimmerElementType.line,
              width: "80%",
              height: 10,
              verticalAlign: "bottom",
            },
            { type: ShimmerElementType.gap, width: "20%", height: 20 },
            {
              type: ShimmerElementType.line,
              width: "40%",
              height: 10,
              verticalAlign: "bottom",
            },
            { type: ShimmerElementType.gap, width: "2%", height: 20 },
            {
              type: ShimmerElementType.line,
              width: "58%",
              height: 10,
              verticalAlign: "bottom",
            },
          ]}
        />
      </div>
    </div>
  );
};

const ImageComponent = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const { card } = props;

  useEffect(() => {
    postData(
      {
        downloadTypeId: card.pageTypeId,
        requestOriginatorId: card.pageId,
        requestToken: card.requestToken,
      },
      APIEndPoints.DOWNLOAD_TAKETOUR_IMAGES,
      false
    )
      .then((item) => {
        setImageUrl(item.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <>
      {imageUrl === "" ? (
        <Shimmer
          customElementsGroup={getCustomElementsExampleThree()}
          width="90%"
        />
      ) : (
        <Image width="auto" alt={card.pageHeader} src={imageUrl} />
      )}
    </>
  );
};
